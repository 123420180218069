import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import image from '../../../assets/img/svg/emailv.svg'

const Section3 = () => {
  return (
    <div className="d-flex">
    <Container className=" vh-100 d-flex justify-content-center">
      <Row className="d-flex justify-content-center align-items-center">
        <Col lg={4} md={6} className="text-center text-lg-start">
          <h3 className="fw-bold">
          Your Data, Your Privacy, <span className="text-orange">Guaranteed</span>
          </h3>
          <p className="text-muted fs-5">
          We’re built on trust. Every lead you upload stays safe, encrypted, and fully compliant with GDPR, CCPA, and other leading data regulations.
          </p>
        </Col>
        <Col lg={8} md={6}>
          <img src={image} alt="Refine Data" className="img-fluid" />
        </Col>
      </Row>
    </Container>
    </div>
  );
};

export default Section3;
