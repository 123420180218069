import { getCookie } from "../../../utils/auth";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../../utils/constant";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Myaccount from "../../../components/account/Myaccount";

const Profile = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    credits: 0,
    firstName: "",
    lastName: "",
    planExpiresOn: "",
    planName: "",
    email: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [expireAlert, setExpireAlert] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [pincode, setPincode] = useState("");
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [pushNotifications, setPushNotifications] = useState(false);

  const headers = {
    Authorization: `Bearer ${getCookie("bearerToken")}`,
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL}dashboard/getMergedData`, {
          headers,
        });
  
        const userData = response.data.data.find(
          (item) => item.source === "user"
        );
  
        if (userData) {
          const {
            credits,
            firstName,
            lastName,
            planExpiresOn,
            planName,
            email,
            status,
            address,
          } = userData;
  
          setUser({ credits, firstName, lastName, planExpiresOn, planName, email });
          setFirstName(firstName);
          setLastName(lastName);
          setEmail(email);
  
          if (status !== "Active") setShowAlert(true);
          if (new Date(planExpiresOn) < new Date()) setExpireAlert(true);
  
          if (address) {
            setAddress1(address.address_line_one || "");
            setAddress2(address.address_line_two || "");
            setCity(address.city || "");
            setState(address.state || "");
            setCountry(address.country || "");
            setPincode(address.postal || "");
          }
        }
      } catch (error) {
        if (error.response?.status === 401) {
          navigate("/");
        } else {
          toast.error("Failed to fetch data");
        }
      }
    };
  
    fetchData();
  }, []);
  
  const handleAddressSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      address_line_one: address1,
      address_line_two: address2,
      city: city,
      state: state,
      country: country,
      postal: pincode,
    };

    try {
      const response = await axios.post(
        `${baseURL}payment/saveBillingAddress`,
        payload,
        { headers } 
      );

      if (response.status === 200) {
        toast.success("Address saved successfully!");
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
          "Failed to save address. Please try again."
      );
    }
  };

  const handleToggle = async (type, value) => {
    try {
      
      const response = await axios.post("/api/notification-settings", {
        type,
        value,
      });
    } catch (error) {
    }
  };

  

  return (
    <>
      <Myaccount />
    </>
  );
};

export default Profile;
