import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../../utils/auth";
// import { fetchCampaigns } from "../../../redux/campaignSlice";
import Swal from "sweetalert2";
import { Col, Container, Row, Button, Dropdown, Form } from "react-bootstrap";
// import { GoPlus } from "react-icons/go";
import DataTable from "react-data-table-component";
import Loading from "../../../assets/img/dashbord/loading.gif";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { GoSearch, GoPlus } from "react-icons/go";
import { LuRefreshCcw } from "react-icons/lu";
import { fetchDashboardData } from "../../../redux/dashboardSlice";

const PastCampaignRedux = () => {
  const [searchText, setSearchText] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");
  const [dateFilter, setDateFilter] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bearerToken = getCookie("bearerToken");

  const { latestCampaigns: campaignData, isLoading } = useSelector(
    (state) => state.dashboard
  );

  // Fetch data only if it's not already available and not loading
  useEffect(() => {
    // Fetch data only once during the initial load
    if (!campaignData) {
      dispatch(fetchDashboardData());
    }
  }, [dispatch]);

  // Memoize filtered data
  const filteredData = useMemo(() => {
    if (!campaignData) return [];
    let filtered = campaignData;

    // Filter by search text (Campaign Name only)
    if (searchText.trim()) {
      const searchTerm = searchText.toLowerCase();
      filtered = filtered.filter((row) =>
        row.campaignName.toLowerCase().includes(searchTerm)
      );
    }

    // Filter by status
    if (statusFilter !== "All") {
      filtered = filtered.filter((row) => row.status === statusFilter);
    }

    // Filter by date
    if (dateFilter) {
      // Ensure the date format is comparable
      const filterDate = new Date(dateFilter).toLocaleDateString();
      filtered = filtered.filter(
        (row) => new Date(row.createdAt).toLocaleDateString() === filterDate
      );
    }

    return filtered;
  }, [campaignData, searchText, statusFilter, dateFilter]);

  const handleRefresh = () => {
    dispatch(fetchDashboardData()); // Refresh campaign data
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete this campaign?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f76a28",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
       
      }
    });
  };

  const handleEdit = (id, campaignName) => { 
    //redirect ot view campaign page
    Swal.fire({
      title: `Do you want to rename <b>${campaignName.toUpperCase()}</b>?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f76a28",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      input: 'text',
      inputLabel: 'New Campaign Name',
      inputPlaceholder: 'Enter the new name',
      inputValidator: (value) => {
        if (!value) {
          return 'Please enter a campaign name!';
        }
      }
    }).then(async (result) => {
      if (result.isConfirmed && result.value) {
        const newCampaignName = result.value;
      }
    });
    
    
  };
  

  // Dropdown Action Component
  

  // Custom Toggle for Dropdown
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{ display: "inline-block" }}
    >
      {children}
    </div>
  ));

  // Column definitions for DataTable
  const columns = [
    {
      name: "Sr. No",
      selector: (row, index) => (index + 1).toString().padStart(2, "0"),
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) => {
        const date = new Date(row.createdAt);
        const formattedDate = date.toLocaleDateString("en-GB"); 
        return formattedDate;
      },
      sortable: true,
    },
    {
      name: "Campaign Name",
      selector: (row) => 
        row.campaignName.charAt(0).toUpperCase() + row.campaignName.slice(1),
      sortable: true,
    },
    
    {
      name: "Status",
      cell: (row) => (
        <span className="status-badge">
          <span
            className={`badge1 ${
              row.status === "New"
                ? "status-new"
                : row.status === "Pending"
                ? "status-pending"
                : row.status === "Complete"
                ? "status-complete"
                : ""
            }`}
          >
            {row.status === "Pending" ? "In Progress" : row.status}
          </span>
        </span>
      ),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <BiDotsHorizontalRounded size={24} />
        </Dropdown.Toggle>
        <Dropdown.Menu className="custom-dropdown-menu">
          <Dropdown.Item 
            onClick={() => navigate(`/account/campaign/${row._id}`)}
          >
            <MdOutlineRemoveRedEye className="me-2" /> View
          </Dropdown.Item>
        
          <Dropdown.Item
            className="text-orange"
            onClick={() => handleEdit(row._id, row.campaignName)} // Updated Edit action
          >
            <AiOutlineEdit className="me-2" /> Edit
          </Dropdown.Item>
          <Dropdown.Item
            className="text-danger"
            onClick={() => handleDelete(row._id)}
          >
            <AiOutlineDelete className="me-2" /> Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      ),
    },
  ];

  return (
    <div className="container-fluid my-4">
    {isLoading ? (
      <div className="text-center">
        <img src={Loading} alt="Loading" width={25} />
      </div>
    ) : (
      <>
        <div className="d-flex justify-content-end px-5">
          <Button
            onClick={handleRefresh}
            className="bg-transparent border-0 d-flex align-items-center"
          >
            <LuRefreshCcw size={20} className="me-1 sq-db-icon" />
          </Button>
        </div>
  
        <Row className="p-4">
          <Col lg={12}>
            <Row className="d-flex align-items-center">
              <Col lg={8}>
                <h4 className="fw-bold mb-4">Campaigns</h4>
              </Col>
              <Col lg={4} className="d-flex justify-content-end">
                <Button
                  variant="btn btn-orange py-2"
                  onClick={() => navigate("/account/createcampaign")}
                  className="d-flex align-items-center"
                >
                  <GoPlus size={20} className="me-1" />
                  New Campaign
                </Button>
              </Col>
            </Row>
  
            <Row className="py-3">
              <Col lg={4}>
                <div className="search-input-container">
                  <GoSearch className="search-icon" />
                  <input
                    type="text"
                    className="form-control search-input"
                    placeholder="Search Campaigns"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              </Col>
  
              <Col lg={4}>
                <Form.Select
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                >
                  <option value="All">All Status</option>
                  <option value="New">New</option>
                  <option value="Pending">Pending</option>
                  <option value="Complete">Complete</option>
                </Form.Select>
              </Col>
  
              <Col lg={4}>
                <input
                  type="date"
                  className="form-control"
                  value={dateFilter}
                  onChange={(e) => setDateFilter(e.target.value)}
                />
              </Col>
            </Row>
  
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              className="overflow-visible py-3"
            />
          </Col>
        </Row>
      </>
    )}
  </div>
  
  );
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

export default PastCampaignRedux;
