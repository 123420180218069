import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../utils/constant";
import { getCookie } from "../../../utils/auth";
import { toast } from "react-toastify";
import { AiTwotoneDelete } from "react-icons/ai";

const ImportLeads = () => {
  const { state } = useLocation();
  const { campaignId } = state || {};
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${baseURL}campaign/getCampaignByCampaignId/${campaignId}`,
        { headers }
      );

      const csvData = response.data?.csvData || [];
      // Add serial number to each row
      const enrichedData = csvData.map((item, index) => ({
        srNo: (index + 1).toString().padStart(2, "0"), // Format: 01, 02, etc.
        ...item,
      }));

      // Set Data
      setData(enrichedData);

      // Generate Columns
      if (enrichedData.length > 0) {
        const dynamicColumns = [
          { name: "Sr No.", selector: (row) => row.srNo, sortable: false },
          ...Object.keys(enrichedData[0])
            .filter((key) => key !== "srNo")
            .map((key) => ({
              name: key
                .replace(/([A-Z])/g, " $1") // Split camelCase into words
                .replace(/^./, (str) => str.toUpperCase()), // Capitalize
              selector: (row) => row[key],
              sortable: true,
            })),
          // {
          //   name: "Actions",
          //   cell: (row) => (
          //     <button
          //       onClick={() => handleDelete(row.srNo)}
          //       className="delete-btn"
          //     >
          //       <AiTwotoneDelete />
          //     </button>
          //   ),
          //   ignoreRowClick: true,
          //   allowOverflow: true,
          //   button: true,
          // },
        ];
        setColumns(dynamicColumns);
      } else {
        setColumns([]);
      }
    } catch (error) {
      toast.error("Failed to fetch data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = (srNo) => {
    const filteredData = data.filter((row) => row.srNo !== srNo);
    setData(filteredData);
    toast.success("Lead deleted successfully!");
  };

  useEffect(() => {
    if (campaignId) {
      fetchData();
    }
  }, [campaignId]);

  return (
    <div className="import-leads-container">
      <h3 className=" fw-bold ms-1">
  {isLoading ? (
    "Loading Leads..."
  ) : (
    <>
      <span className="title">{data.length}</span> Leads Imported
    </>
  )}
</h3>
      {isLoading ? (
        <p>Loading...</p>
      ) : data.length > 0 ? (
        <>
          <DataTable
            columns={columns}
            data={data}
            pagination
            customStyles={customStyles}
            className="import-leads-table"
          />
         <div className="text-end start-campaign-btn-cnt">
         <button className="start-campaign-btn btn btn-orange">Start Campaign</button>
         </div>
        </>
      ) : (
        <p>No Data Found</p>
      )}
    </div>
  );
};

export default ImportLeads;

// Custom Styles for DataTable
const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // Overwrite row height
    },
  },
  headCells: {
    style: {
      backgroundColor: "#f5f5f5",
      fontWeight: "bold",
    },
  },
};
