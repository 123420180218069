import Container from "react-bootstrap/Container";
import { Row, Col, Button, Pagination } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import { getCookie } from "../../../utils/auth";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { baseURL } from "../../../utils/constant";
import { toast } from "react-toastify";
import Alert from "react-bootstrap/Alert";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import * as Icon from "react-bootstrap-icons";
import Loading from "../../../assets/img/dashbord/loading.gif";
import { saveAs } from "file-saver";
import { FaList } from "react-icons/fa";
import { FaCheck, FaExclamation } from "react-icons/fa6";
import Swal from "sweetalert2";
import { FiDownload } from "react-icons/fi";
import { GoSearch } from "react-icons/go";
import { TfiLayoutGrid3 } from "react-icons/tfi";
import { LuBadgeCheck, LuX } from "react-icons/lu";
import { RxCrossCircled } from "react-icons/rx";
import { PiWarningLight } from "react-icons/pi";


const CampaignDetails = () => {
  const { _id } = useParams();

  const [IdData, setIdData] = useState({
    campaignName: "",
    status: "",
    recordCount: "",
  });
  const [Data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [selectedEntriesPerPage, setSelectedEntriesPerPage] = useState(50);
  const [searchQuery, setSearchQuery] = useState("");
  const [fileInputDisabled, setFileInputDisabled] = useState(true);
  const [viewMode, setViewMode] = useState("grid");
  const [showImportButton, setShowImportButton] = useState(true);
  const [crossIconVisible, setCrossIconVisible] = useState(false);
  const [show, setShow] = useState(true);

  const navigate = useNavigate();

  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };

  const fetchData = () => {
    axios
      .get(`${baseURL}campaign/getCampaignByCampaignId/${_id}`, {
        headers: headers,
      })
      .then((response) => {
        const {
          campaignName,
          status,
          recordCount,
          csvData,
          qualifiedData = [],
          leadFields,
          isCSV,
          emailCheck,
          personCheck,
          companyCheck,
          voiceCallCheck,
        } = response.data;

        setIdData({
          isCSV,
          emailCheck,
          personCheck,
          companyCheck,
          voiceCallCheck,
          campaignName,
          status,
          recordCount,
          leadFields,
        });
        setData(qualifiedData);
        setIsLoading(false);
        const totalItems = csvData.totalItems || 0;
        setTotalPages(Math.ceil(totalItems / entriesPerPage));
      })
      .catch((err) => {
        if (err.response && err.response.status === 400) {
          return navigate("/login");
        }
        if (err.response && err.response.status === 401) {
          return navigate("/");
        }
        setIsLoading(false);
        toast(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const filteredData =
    Data && Array.isArray(Data)
      ? Data.filter((item) => {
          const fullName =
            `${item.first_name} ${item.last_name} ${item.email} `.toLowerCase();
          return fullName.includes(searchQuery.toLowerCase());
        })
      : [];

  const handleEntriesPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    setSelectedEntriesPerPage(selectedValue);
    setCurrentPage(1);
  };
  const startIndex = (currentPage - 1) * selectedEntriesPerPage;
  const endIndex = startIndex + selectedEntriesPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setFileInputDisabled(false);
  }, [Data]);

  const fileInputRef = useRef(null);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setShowImportButton(false);
    setCrossIconVisible(true);
    showToast("File selected successfully");
  };

  const showToast = (message) => {
    toast(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const handleImportClick = async () => {
    fileInputRef.current.click();
  };
  const showToastComponent = (message) => {
    toast.info(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const handleSubmit = async () => {
    try {
      if (!selectedFile) {
        showToastComponent("Please select a file for import");
        return;
      }

      Swal.fire({
        title:
          "<h6>Uploading this file will result in a deduction of your credits. Are you sure you want to proceed with the upload?</h6>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f76a28",
        cancelButtonColor: "#000",
        confirmButtonText: "Yes, upload it!",
        cancelButtonText: "Cancel",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const formData = new FormData();
          formData.append("file", selectedFile);
          formData.append("campaignId", _id);

          try {
            //in below code can you add logic how much percent its one

            const response = await axios.post(
              `${baseURL}campaign/importAnyFile`,
              formData,
              {
                headers: { ...headers },
              }
            );

            window.location.reload();
            toast(response.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            if (response.status === 200) {
              navigate(`/account/campaign/${_id}`);
            } else {
              toast(response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          } catch (error) {
            toast(error?.response?.data?.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            if (error.response && error.response.status === 401) {
              navigate("/login");
            }
          }
        }
      });
    } catch (err) {
    }
  };

  const DownloadFile = () => {
    axios
      .get(
        `${baseURL}campaign/downloadSampleImportFileByCampaignIdForClient/${_id}`,
        {
          headers: { ...headers },
          responseType: "blob",
        }
      )
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        const contentDisposition = response.headers["content-disposition"];
        const filenameMatch =
          contentDisposition && contentDisposition.match(/filename=(.+)$/);
        const filename = filenameMatch
          ? filenameMatch[1]
          : `sample_import_${_id}.csv`;

        link.setAttribute("download", filename);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        toast(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((err) => {
        toast(err.response?.data?.message || "Failed to download file", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPagination = () => {
    const pageNumbers = [];
    const totalPages = Math.ceil(filteredData.length / selectedEntriesPerPage);
    const maxVisiblePages = 5;

    // Calculate the range of page numbers to display
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    // Adjust startPage and endPage if endPage is too close to totalPages
    if (endPage - startPage < maxVisiblePages - 1) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return (
      <Pagination>
        {currentPage > 1 && (
          <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
            &laquo;
          </Pagination.Item>
        )}

        {pageNumbers.map((number) => (
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => handlePageChange(number)}
            className="bg-white"
          >
            {number}
          </Pagination.Item>
        ))}

        {currentPage < totalPages && (
          <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
            &raquo;
          </Pagination.Item>
        )}
      </Pagination>
    );
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.get(
        `${baseURL}campaign/deleteCampaign/${id}`,
        {
          headers: headers,
        }
      );

      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        navigate("/account/campaign");
      } else {
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Failed to delete campaign",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFileChange({ target: { files: [file] } });
  };

  const downloadCSV = () => {
    const csvRows = [];
    const headers = [
      "First Name",
      "Last Name",
      "Job Title",
      "Email",
      "Phone Number",
      "Company Name",
      "status",
    ];
    csvRows.push(headers.join(","));

    currentData.forEach((item) => {
      const row = [
        item.first_name,
        item.last_name,
        item.jobTitle,
        item.email,
        item.phone_number,
        item.company_name,
        item.status,
      ];
      csvRows.push(row.join(","));
    });

    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    saveAs(blob, "contacts.csv");
  };

  const calculateProgress = (data) => {
    const initialStep = { name: "Import CSV", completed: data.isCSV };

    const otherSteps = [
      { name: "Email Lookup", completed: data.emailCheck },
      { name: "Prospect Verification", completed: data.personCheck },
      { name: "Company Verification", completed: data.companyCheck },
      { name: "Voice Authentication", completed: data.voiceCallCheck },
    ];

    otherSteps.sort((a, b) => b.completed - a.completed);

    const allStepsCompleted = [initialStep, ...otherSteps].every(
      (step) => step.completed
    );

    const finalStep = {
      name: "Completed",
      completed: allStepsCompleted,
      finalStep: true,
    };

    return [
      initialStep,
      ...otherSteps,
      ...(allStepsCompleted ? [finalStep] : []),
    ];
  };

  const steps = calculateProgress(IdData);

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <img src={Loading} alt="" width={25} />
        </div>
      ) : (
        <>
          <Container fluid>
            <Row>
              <Col className="p-4">
                <Row>
                  <Col lg={12} className="mx-auto">
                    <div className="mb-3">
                      <Link
                        to="/account/campaigns"
                        className="text-decoration-none link-style-none text-black fs-6"
                      >
                        <span className="text-muted">Back to Campaigns</span>
                      </Link>
                    </div>
                    <div className="d-flex justify-content-between">
                      <Row className="w-100">
                        <Col className="d-flex align-items-center">
                          <h4 className="fw-bold me-3">
                            {IdData.campaignName.charAt(0).toUpperCase() +
                              IdData.campaignName.slice(1)}
                          </h4>
                          <Badge
                            className={
                              IdData.status === "Pending"
                                ? "status-pending"
                                : IdData.status === "New"
                                ? "status-new"
                                : "status-complete"
                            }
                          >
                            {IdData.status === "Pending"
                              ? "In Progress"
                              : IdData.status}
                          </Badge>
                        </Col>
                        <Col className="d-flex justify-content-end align-items-center px-0 ">
                          <div className="fs-4">
                            <span className="text-orange">
                              {IdData.recordCount}
                            </span>{" "}
                            Contacts
                          </div>
                        </Col>
                      </Row>

                      {IdData.status === "New" ? (
                        <Button
                          className="mb-4"
                          variant="btn btn-orange"
                          onClick={() => handleDelete(_id)}
                        >
                          Delete
                        </Button>
                      ) : null}
                    </div>
                    {/* Headers Fileds and Download */}
                    <Row className="py-1">
                      <Col>
                        <div className="px-2 py-3 text-dark fs-6">
                          {" "}
                          Headers{" "}
                        </div>
                      </Col>
                    </Row>
                    <Row className="d-flex justify-content-start py-0">
                      <Col>
                        {IdData.leadFields && IdData.leadFields.length > 0
                          ? IdData.leadFields.map((field) => (
                              <Badge
                                className="me-2 px-3 py-2 rounded-5 text-dark campaign-complete-badge"
                                key={field._id}
                              >
                                {field.label}
                              </Badge>
                            ))
                          : null}
                      </Col>
                    </Row>

                    <div className="d-flex justify-content-end">
                      {IdData.status === "Complete" ? (
                        <Button
                          className="mt-2"
                          style={{ fontSize: "medium" }}
                          variant="btn btn-orange"
                          onClick={downloadCSV}
                        >
                          <FiDownload className="rotate-icon me-1" />
                          Export CSV
                        </Button>
                      ) : null}
                    </div>

                    <hr className="mb-4" />
                  </Col>
                </Row>

                <Row>
                  <Col lg={12} className="mx-auto">
                    {IdData.status === "New" ? (
                      <Col
                        lg={12}
                        className="mx-auto rounded-3 text-center text-black "
                      >
                        <Alert className="text-center" variant="warning">
                          <Icon.ExclamationTriangleFill className="text-warning me-2" />
                          Please take a look at the csv file format{" "}
                          <a
                            className="text-decoration-none cursor-pointer"
                            onClick={DownloadFile}
                          >
                            here
                          </a>{" "}
                          before importing the contacts.{" "}
                        </Alert>
                        {showImportButton && (
                          <>
                            <div className="text-center">
                              <div
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                                className="p-5 rounded-3 bg-white"
                              >
                                <Button
                                  className="mb-4"
                                  variant="btn btn-orange"
                                  onClick={handleImportClick}
                                  disabled={fileInputDisabled}
                                >
                                  Import csv document
                                </Button>

                                <input
                                  type="file"
                                  ref={fileInputRef}
                                  style={{ display: "none" }}
                                  onChange={handleFileChange}
                                />
                                <p>or drop a file</p>
                              </div>
                            </div>
                          </>
                        )}

                        {showImportButton && (
                          <>
                            <a
                              to={`/campaign/${_id}`}
                              className="btn btn-orange mb-4"
                              onClick={handleImportClick}
                              disabled={fileInputDisabled}
                              style={{ display: "none" }}
                            >
                              Submit
                            </a>

                            <input
                              type="file"
                              ref={fileInputRef}
                              style={{ display: "none" }}
                              onChange={handleFileChange}
                            />
                          </>
                        )}

                        {crossIconVisible && (
                          <>
                            <p
                              className="mb-4 text-success cursor-pointer"
                              onClick={() => {
                                setShowImportButton(true);
                                setCrossIconVisible(false);
                                setSelectedFile(null);
                              }}
                            >
                              Selected File: {selectedFile.name} &#10006;
                            </p>
                            <span className="mb-4"></span>
                            <a
                              className="mb-4 btn btn-orange"
                              onClick={handleSubmit}
                            >
                              Submit
                            </a>
                          </>
                        )}
                      </Col>
                    ) : IdData.status === "Pending" ? (
                      <Col lg={5} className="mx-auto text-center">
                        <p className="text-muted">
                          Sit back and relax. Your contacts are getting
                          verified.
                        </p>
                        <div className="row">
                          <section className="col-lg-12">
                            <h3 className="mb10"></h3>
                            <div className="progress-div d-flex justify-content-center ">
                              <ul className="progress vertical py-5 vh-60">
                                {steps.map((step, index) => (
                                  <li
                                    key={index}
                                    className={
                                      step.finalStep
                                        ? "inactive final-step"
                                        : step.completed
                                        ? "active"
                                        : "inactive"
                                    }
                                  >
                                    {step.finalStep ? (
                                      <FaExclamation
                                        style={{ color: "grey" }}
                                        className="crossmark"
                                      />
                                    ) : step.completed ? (
                                      <FaCheck
                                        style={{ color: "white" }}
                                        className="checkmark"
                                      />
                                    ) : (
                                      <FaExclamation
                                        style={{ color: "black" }}
                                        className="crossmark"
                                      />
                                    )}
                                    <span
                                      style={{
                                        fontWeight: step.finalStep
                                          ? "normal"
                                          : step.completed
                                          ? "bold"
                                          : "normal",
                                        color: step.finalStep
                                          ? "grey"
                                          : "black",
                                      }}
                                    >
                                      {step.name}
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </section>
                        </div>
                      </Col>
                    ) : IdData.status === "Complete" ? (
                      <>
                        <Row className="me-auto">
                          <Row className="m-0 p-0">
                            <Col lg={12}>
                              {show && (
                                <Alert
                                  variant="warning"
                                  onClose={() => setShow(false)}
                                  dismissible
                                >
                                  <Icon.ExclamationTriangleFill className="text-warning me-2" />
                                  <span className="me-2">
                                    Do you want to enrich the data?
                                  </span>
                                  <Button
                                    className="me-2"
                                    style={{ fontSize: "small" }}
                                    variant="btn btn-orange"
                                    onClick={downloadCSV}
                                  >
                                    Yes
                                  </Button>
                                </Alert>
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={12} className="my-2  ">
                              <h4>Your contacts</h4>
                            </Col>
                          </Row>
                          <Row className="align-items-center justify-content-between py-2">
                            {/* Search Input */}
                            <Col lg={6} className="d-flex align-items-center">
                              <Form.Group className="w-75 me-3 position-relative">
                                <Form.Control
                                  type="text"
                                  placeholder="Search..."
                                  value={searchQuery}
                                  onChange={(e) => handleSearch(e.target.value)}
                                  className="ps-5"
                                />
                                <GoSearch className="position-absolute top-50 translate-middle-y ms-2 text-muted" />
                              </Form.Group>
                              <Form.Select
                                className="w-25"
                                value={selectedEntriesPerPage}
                                onChange={handleEntriesPerPageChange}
                              >
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={200}>200</option>
                                <option value={500}>500</option>
                              </Form.Select>
                            </Col>

                            {/* List and Grid Icons */}
                            <Col lg={3} className="d-flex justify-content-end">
                              {/* List View Icon */}
                              <div
                                className={`icon1 ${
                                  viewMode === "list" ? "active1" : ""
                                }`}
                                onClick={() => setViewMode("list")}
                              >
                                <FaList
                                  size={20}
                                  color={
                                    viewMode === "list" ? "#ED7B00" : "#5f5f5f"
                                  }
                                />
                              </div>

                              {/* Grid View Icon */}
                              <div
                                className={`icon1 ms-3 ${
                                  viewMode === "grid" ? "active1" : ""
                                }`}
                                onClick={() => setViewMode("grid")}
                              >
                                <TfiLayoutGrid3
                                  size={20}
                                  color={
                                    viewMode === "grid" ? "#ED7B00" : "#5f5f5f"
                                  }
                                />
                              </div>
                            </Col>
                          </Row>
                        </Row>
                        <Row>
                          {currentData && currentData.length > 0 ? (
                            viewMode === "list" ? (
                              currentData.map((item, index) => (
                                <Col
                                  lg={12}
                                  key={index}
                                  className="bg-white py-3 px-4 rounded mb-3"
                                >
                                  <Row>
                                    <Col
                                      md={4}
                                      className="d-flex align-items-center"
                                    >
                                      {/* <BsPersonCircle
                                        className="me-2"
                                        style={{
                                          fontSize: "25px",
                                          color: "#000",
                                        }}
                                      /> */}
                                      <div>
                                        <p
                                          className="mb-0"
                                          style={{ fontSize: "18px" }}
                                        >
                                          {item.status === "Pending" ? (
                                            <PiWarningLight className="text-warning me-2" />
                                          ) : item.status === "Verified" ? (
                                            <LuBadgeCheck className="text-success me-2" />
                                          ) : item.status === "Failed" ? (
                                            <RxCrossCircled className="text-danger me-2" />
                                          ) : item.status === "" ? (
                                            <PiWarningLight className="text-warning me-2" />
                                          ) : null}
                                          {item.first_name || "N/A"}{" "}
                                          {item.last_name || "N/A"}
                                        </p>
                                        <p
                                          className="mb-0 "
                                          style={{ fontSize: "14px" }}
                                        >
                                          <Icon.BagFill />{" "}
                                          {item.job_title || "N/A"}
                                        </p>
                                      </div>
                                    </Col>

                                    <Col md={4}>
                                      <p
                                        className="mb-0"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <Icon.EnvelopeFill />{" "}
                                        {item.email || "N/A"}
                                      </p>
                                      <p
                                        className="mb-0"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <Icon.TelephoneFill />{" "}
                                        {item.phone_number || "N/A"}
                                      </p>
                                    </Col>

                                    <Col md={4}>
                                      <p
                                        className="mb-0"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <Icon.PostcardFill />{" "}
                                        {item.company_name || "N/A"}
                                      </p>
                                      <p
                                        className="mb-0"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <Icon.BuildingFill />{" "}
                                        {item.industry || "N/A"}
                                      </p>
                                    </Col>
                                  </Row>
                                </Col>
                              ))
                            ) : (
                              currentData.map((item, index) => (
                                <Col lg={4} key={index} className="mb-3">
                                  <div className="p-3 bg-white rounded shadow-sm">
                                    {/* Header with Name and Status */}
                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                      <h6 className="fw-semibold mb-0">
                                        {item.first_name || "N/A"}{" "}
                                        {item.last_name || "N/A"}
                                      </h6>
                                      <div>
                                        {item.status === "Pending" && (
                                          <PiWarningLight className="text-warning" />
                                        )}
                                        {item.status === "Verified" && (
                                          <LuBadgeCheck className="text-success" />
                                        )}
                                        {item.status === "Failed" && (
                                          <RxCrossCircled className="text-danger" />
                                        )}
                                      </div>
                                    </div>

                                    {/* Details Section */}
                                    <div className="mb-2">
                                      <Row className=" border-top">
                                        <Col
                                          lg={4}
                                          md={4}
                                          xl={4}
                                          className="border-end "
                                        >
                                          <div>
                                            <p
                                              className="mb-1 text-muted"
                                              style={{ fontSize: "14px" }}
                                            >
                                              <strong>Email:</strong>{" "}
                                            </p>
                                          </div>
                                          <div>
                                            <p
                                              className="mb-1 text-muted"
                                              style={{ fontSize: "14px" }}
                                            >
                                              <strong>Company:</strong>
                                            </p>
                                          </div>{" "}
                                          <div>
                                            <p
                                              className="mb-1 text-muted"
                                              style={{ fontSize: "14px" }}
                                            >
                                              <strong>Industry:</strong>{" "}
                                            </p>
                                          </div>
                                          <div>
                                            <p
                                              className="mb-1 text-muted"
                                              style={{ fontSize: "14px" }}
                                            >
                                              <strong>Job Title:</strong>{" "}
                                            </p>
                                          </div>
                                          <div>
                                            <p
                                              className="mb-0 text-muted"
                                              style={{ fontSize: "14px" }}
                                            >
                                              <strong>Contact:</strong>{" "}
                                            </p>
                                          </div>
                                        </Col>
                                        <Col lg={8} md={8} xl={8} className="">
                                          <div
                                            className="truncate"
                                            title={item.email || "N/A"}
                                          >
                                            {item.email || "N/A"}
                                          </div>
                                          <div
                                            className="truncate"
                                            title={item.company_name || "N/A"}
                                          >
                                            {item.company_name || "N/A"}
                                          </div>
                                          <div
                                            className="truncate"
                                            title={item.industry || "N/A"}
                                          >
                                            {item.industry || "N/A"}
                                          </div>
                                          <div
                                            className="truncate"
                                            title={item.job_title || "N/A"}
                                          >
                                            {item.job_title || "N/A"}
                                          </div>
                                          <div
                                            className="truncate"
                                            title={item.phone_number || "N/A"}
                                          >
                                            {item.phone_number || "N/A"}
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </Col>
                              ))
                            )
                          ) : (
                            <p>No contacts available.</p>
                          )}
                        </Row>

                        <div className="float-end">{renderPagination()}</div>
                      </>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default CampaignDetails;
