import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchDashboardData } from "../../../redux/dashboardSlice";
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";
import Loading from "../../../assets/img/dashbord/loading.gif";
import { PiCoinsThin, PiUsersThin } from "react-icons/pi";
import { CiBullhorn } from "react-icons/ci";
import UsageCharts from "../../../components/dashboard/charts/UsageCharts";
import CampaignChart from "../../../components/dashboard/charts/CampaignChart";
import RecentCampaigns from "../../../components/dashboard/RecentCampaigns";
import Leads from "../../../components/dashboard/Leads";
import Credits from "../../../components/dashboard/Credits";
import { LuRefreshCcw } from "react-icons/lu";
import DashboardCredits from "../../../components/dashboard/DashboardCredits";

const DashboardRedux = () => {
  const dispatch = useDispatch();
  const { isLoading, dashboardData, latestCampaigns, usageData, error } =
    useSelector((state) => state.dashboard);

  useEffect(() => {
    // Fetch data only if the dashboard data is not available in the Redux store
    if (!dashboardData.campaignCount) {
      dispatch(fetchDashboardData());
    }
  }, [dispatch, dashboardData]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <img src={Loading} alt="loading" width={25} />
      </div>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="sq-dashboard py-3 px-5">
      <Container fluid className="py-4">
        <Row className="g-4">
          <Col xs={12} sm={6} md={4} lg={4}>
            <div className="p-3 rounded-3 bg-white border-orange">
              <div className="d-flex align-items-center">
                <PiUsersThin size={"70px"} className="sq-db-icon" />
                <div className="ms-3">
                  <h2 className="fw-bold display-7 mb-1">
                    {dashboardData.leadCount}
                  </h2>
                  <span className="fw-normal">Leads</span>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4} lg={4}>
            <div className="p-3 rounded-3 bg-white border-orange">
              <div className="d-flex align-items-center">
                <CiBullhorn size={"70px"} className="sq-db-icon" />
                <div className="ms-3">
                  <h2 className="fw-bold display-7 mb-1">
                    {dashboardData.campaignCount}
                  </h2>
                  <span className="fw-normal">Campaigns</span>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4} lg={4}>
            <DashboardCredits dashboardData={dashboardData} />
          </Col>
        </Row>
      </Container>
      <Container fluid className="py-4">
        <Row className="gap-2 gap-lg-4 d-flex justify-content-around">
          <Col
            xs={12}
            md={6}
            lg={6}
            className="bg-white border theme-border rounded-3 p-3"
          >
             <h6 className="fw-bold">Usage</h6>
            <UsageCharts data={usageData} />
          </Col>
          <Col
            xs={12}
            md={5}
            lg={5}
            className="bg-white border rounded-3 theme-border p-3"
          >
            <h6 className="fw-bold">Campaigns</h6>
            <CampaignChart className="mt-8" data={dashboardData.pieChart} />
          </Col>
        </Row>
      </Container>
      <Container fluid className="py-4">
        <Row className="gap-2 gap-lg-4 d-flex justify-content-around">
          <Col
            lg={3}
            md={6}
            sm={12}
            className="bg-white p-3 rounded-3 theme-border border"
          >
            <Leads dashboardData={dashboardData} />
          </Col>
          <Col
            lg={3}
            md={6}
            sm={12}
            className="bg-white p-3 rounded-3 theme-border border"
          >
            <Credits dashboardData={dashboardData} />
          </Col>
          <Col lg={5} md={12} className="bg-white p-3 rounded-3 theme-border border">
            <RecentCampaigns latestCampaigns={latestCampaigns} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DashboardRedux;
