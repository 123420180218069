import React, { useEffect, useState } from "react";
import { Badge, Col, Container, Form, Row } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCookie } from "../../../utils/auth";
import { toast } from "react-toastify";
import axios from "axios";
import { baseURL } from "../../../utils/constant";

const ShowHeaders = () => {
  const { _id } = useParams();

  const [IdData, setIdData] = useState({
    campaignName: "",
    status: "",
    recordCount: "",
  });
  const [Data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [selectedEntriesPerPage, setSelectedEntriesPerPage] = useState(50);
  const [searchQuery, setSearchQuery] = useState("");
  const [fileInputDisabled, setFileInputDisabled] = useState(true);
  const [viewMode, setViewMode] = useState("grid");
  const [showImportButton, setShowImportButton] = useState(true);
  const [crossIconVisible, setCrossIconVisible] = useState(false);
  const [show, setShow] = useState(true);

  const navigate = useNavigate();
  const state = useLocation();
  const { csvData, rowCount } =state?.state || {} // Extract data and rowCount from state
  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };

  const fetchData = () => {
    axios
      .get(`${baseURL}campaign/getCampaignByCampaignId/${_id}`, {
        headers: headers,
      })
      .then((response) => {
        const {
          campaignName,
          status,
          recordCount,
          csvData,
          qualifiedData = [],
          leadFields,
          isCSV,
          emailCheck,
          personCheck,
          companyCheck,
          voiceCallCheck,
        } = response.data;

        setIdData({
          isCSV,
          emailCheck,
          personCheck,
          companyCheck,
          voiceCallCheck,
          campaignName,
          status,
          recordCount,
          leadFields,
        });
        setData(qualifiedData);
        setIsLoading(false);
        const totalItems = csvData.totalItems || 0;
        setTotalPages(Math.ceil(totalItems / entriesPerPage));
      })
      .catch((err) => {
        if (err.response && err.response.status === 400) {
          return navigate("/login");
        }
        if (err.response && err.response.status === 401) {
          return navigate("/");
        }
        setIsLoading(false);
        toast(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const calculateProgress = (data) => {
    const initialStep = { name: "Import CSV", completed: data.isCSV };

    const otherSteps = [
      { name: "Email Lookup", completed: data.emailCheck },
      { name: "Prospect Verification", completed: data.personCheck },
      { name: "Company Verification", completed: data.companyCheck },
      { name: "Voice Authentication", completed: data.voiceCallCheck },
    ];

    otherSteps.sort((a, b) => b.completed - a.completed);

    const allStepsCompleted = [initialStep, ...otherSteps].every(
      (step) => step.completed
    );

    const finalStep = {
      name: "Completed",
      completed: allStepsCompleted,
      finalStep: true,
    };

    return [
      initialStep,
      ...otherSteps,
      ...(allStepsCompleted ? [finalStep] : []),
    ];
  };

  const steps = calculateProgress(IdData);

  const handleNext = () => {
    const rowCount = IdData.recordCount; // Ensure you are getting this from your API response
    navigate("/account/EnrichLeadsData/", {
      state: { rowCount, campaignId: _id, csvData: csvData },
    });
  };

  return (
    <>
      <Container fluid>
        <Container className="h-100 w-75 p-4 border border-2 rounded bg-white d-flex flex-column">
          <Row>
            <Col>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <div className="fw-bold">2/3</div>
                <div className="fs-5">Set up imported custom headers</div>
                <button className="border-0 bg-transparent">
                  <RxCross1 size={24} />
                </button>
              </div>
            </Col>
          </Row>

          <Row className="flex-grow-1">
            <Col>
              <div className="mb-3 fw-bold">Headers Name</div>
              <div
                className="headersSection border border-2 rounded p-3"
                style={{ maxHeight: "400px", overflowY: "auto" }}
              >
                <div className="d-flex flex-wrap py-1 gap-3">
                  {" "}
                  {/* Added flex-wrap */}
                  {IdData.leadFields && IdData.leadFields.length > 0
                    ? IdData.leadFields.map((field) => (
                        <div
                          className="me-2 border border-bottom p-2 rounded-2  d-flex align-items-center "
                          style={{ width: "calc(50% - 1rem)" }}
                          // Adjust width for two items per row
                          key={field._id}
                        >
                          <Form>
                            {["checkbox"].map((type) => (
                              <div
                                key={`default-${type}`}
                                className="mb-0 mx-1"
                              >
                                <Form.Check
                                  type={type}
                                  id={`default-${type}`}
                                  className="custom-checkbox"
                                />
                              </div>
                            ))}
                          </Form>

                          {field.label}
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="d-flex justify-content-end mt-4">
                <button className="previous mx-2">Previous</button>
                <button className="btn btn-orange" onClick={handleNext}>
                  Continue
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default ShowHeaders;
