import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import images1 from '../../../assets/img/images/Group 8838.png'

function Section1() {
  return (
    <>
      



<Container className="vh-100 d-flex align-items-center  " >
      <Row className="align-items-center g-4">
        
        <Col lg={4} md={12} className="text-center text-lg-start">
          <h2 className="fw-bold ">
          Precision Leads, Verified  
            <span className="text-orange"> by Humans</span>
          </h2>
          <p className="text-muted my-3 fs-6">
          Say goodbye to guesswork. Verify your leads with unmatched accuracy using SmartQC's human-powered verification process.
           
          </p>
          <Link
            className="btn btn-orange rounded-3 px-4 py-2"
            to="/register"
            role="button"
          >
            Start for free
          </Link>
        </Col>

        
        <Col lg={8} md={12} className="text-center">
          <img src={images1} alt="Data quality illustration" className=" img-fluid rounded" />
        </Col>
      </Row>
    </Container>

  

    </>
  );
}

export default Section1;
