import React, { useState, useEffect } from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import logo from "../../../assets/img/svg/nlogo.svg";
import { Link } from "react-router-dom";
import { isLogin, logOut } from "../../../utils/auth"; // Adjust the import path as necessary

function Navigation() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check if the user is logged in when the component mounts
    const checkLoginStatus = async () => {
      const loggedIn = await isLogin();
      setIsLoggedIn(loggedIn);
    };

    checkLoginStatus();
  }, []);

  // Handle logout
  const handleLogout = () => {
    logOut();
    setIsLoggedIn(false);
  };

  return (
    <header>
      <Navbar expand="lg" className="shadow px-4 py-2 sticky-top " bg="light">
        <Container fluid>
          <Navbar.Brand to={"/"} as={Link}>
            <img src={logo} alt="Logo" width="120" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav" className="justify-content-end">
            <Nav className="align-items-center gap-3">
              <Nav.Link as={Link} to="/contact" className="text-dark">
                Contact Us
              </Nav.Link>

              {/* Conditionally render buttons based on login status */}
              {!isLoggedIn ? (
                <>
                  <Link to="/login">
                    <Button variant="me-2 sign" className="me-2">
                      Sign In
                    </Button>
                  </Link>
                  <Button className="btn-orange border-0" to="/register">
                    Start for Free
                  </Button>
                </>
              ) : (
                <>
                  <Link to="/account/dashboard">
                    <Button variant="me-2" className="me-2">
                      My Account
                    </Button>
                  </Link>
                  <Button variant="me-2" onClick={handleLogout}>
                    Logout
                  </Button>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Navigation;
