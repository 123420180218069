import React from "react";
import { Outlet } from "react-router-dom";
import NewSidebar2 from "../components/NewSidebar2";
import SubNav from "../components/subNav";

const SidebarLayout = () => {
  return (
    <div className="d-flex">
      {/* Sidebar */}
      <NewSidebar2 />

      {/* Main Content */}
      <div className="content overflow-scroll vh-100 flex-grow-1">
        <SubNav />
        <Outlet />
      </div>
    </div>
  );
};

export default SidebarLayout;
