import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { baseURL } from "../../utils/constant";
import React, { useState, useEffect } from "react";
import { getCookie, isLogin, setAuthentication } from "../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export default function Login({ setisAuthenticated }) {
  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  useEffect(() => {
    const authenticate = async () => {
      if (await isLogin()) {
        navigate("/");
      }
    };
    authenticate();
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
    setIsButtonDisabled(!email || !password);
  };

  const handleSubmit = async (e) => {
    // const token = localStorage.getItem("token");
    e.preventDefault();
    try {
      const payload = { email };
      const response = await axios.post(
        `${baseURL}auth/resetPassword`,
        payload,
        {
          headers: { ...headers },
        }
      );
      const { message } = response.data;
      toast(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      navigate(`/login`);
     
    } catch (error) {
      toast(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <>
      <div className="vh-100 d-flex align-items-center justify-content-center bg-login bg-white">
        <Container>
          <Row className="text-center mb-4">
            <Col lg={12}>
              <a
            className="text-decoration-none text-dark fw-lighter fs-2"
            href="#"
          >
            smart<span className="text-orange fw-bold">qc</span>
          </a>
            </Col>
          </Row>
          <Row>
            <Col lg={4} className="mx-auto">
              <Col lg={12} className="p-5 rounded-3 bg-white border">
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      className="border-1 border-bottom rounded-3"
                      name="email"
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <div className="d-grid gap-2">
                    <Button
                      variant="btn btn-orange"
                      type="submit"
                      className="rounded-3"
                    >
                      Send password reset link
                    </Button>
                  </div>
                </Form>
                <div className="text-center mt-3">
                  <Link to="/login" className="text-center text-decoration-none">
                    Back to Sign In
                  </Link>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
