import React from "react";
import { Col } from "react-bootstrap";
import { PiCoinsThin } from "react-icons/pi"; // Adjust import based on your setup

const formatValue = (value) => {
  if (value < 1000) {
    return value.toString(); // Keep it as is for numbers below 1000
  } else if (value >= 1000 && value < 1000000) {
    return (value / 1000).toFixed(1).replace(/\.0$/, "") + "K"; // Format to 'K'
  } else if (value >= 1000000 && value < 1000000000) {
    return (value / 1000000).toFixed(1).replace(/\.0$/, "") + "M"; // Format to 'M'
  } else {
    return (value / 1000000000).toFixed(1).replace(/\.0$/, "") + "B"; // Format to 'B'
  }
};

const DashboardCredits = ({ dashboardData }) => (
  <div className="p-3 rounded-3 bg-white border-orange">
    <div className="d-flex align-items-center">
      <PiCoinsThin size={"70px"} className="sq-db-icon" />
      <div className="ms-3">
        <h2 className="fw-bold display-7 mb-1">
          {formatValue(dashboardData.credits)}
        </h2>
        <span className="fw-normal">Credits</span>
      </div>
    </div>
  </div>
);

export default DashboardCredits;
