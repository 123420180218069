import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import image2 from  '../../../assets/img/svg/upload.svg'

const Section5New = () => {
  return (
<>
<div className="bg-body">


<Container  className='py-5 mt-2'>
  <Row className="d-flex justify-content-center align-items-center p-3 bg-body">
    <Col xs={12} md={6} lg={7} className="p-3">
      <img src={image2} alt="SmartQC Overview" className="img-fluid p-3 mb-4" />
    </Col>
    <Col xs={12} md={6} lg={5} className="p-3">
      <h3 className="fw-bold">
      How <span className="text-orange">SmartQC</span>  Works
      </h3>
      <p className="fs-5 text-muted">
        Upload your CSV file, and SmartQC instantly validates your leads
        by identifying invalid or toxic entries. With clean and verified
        data, you can ensure better targeting and more effective marketing
        campaigns. Streamline your lead management process today!
      </p>
    </Col>
  </Row>
</Container>
</div>
</>
  );
};

export default Section5New;
