import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Navbar from "../../components/Navbar.jsx";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer.jsx";


function Cancellation() {
  return (
    <>
      <Container className="py-5">
        <Row>
          <Col lg={8} className="mx-auto">
            <div className="mb-3">
              <Link to="/" className="text-decoration-none text-black">
                Back to Home Page
              </Link>
            </div>
            <h1 className="mb-5">Cancellation and Refund Policy</h1>
            <p>
              We follow a reliable refund policy to ensure a fair relationship
              with all our customers. Please read the guidelines governing the
              cancellation and refund policy for SmartQC and it’s services.
            </p>
            <p>
              If you wish to cancel or delete your account, notify us at least
              15 days before end of your term (via an email to
                compliance@smartqc.io). Upon cancellation, your data is deleted from
              our servers. Since deletion of all data is final please be sure
              that you do in fact want to cancel your account before doing so.
            </p>
            <p>
              Your subscription gets renewed automatically at the end of each
              month. You can upgrade or downgrade the level of our services at
              any time of your usage, however SmartQC does not refund any fees
              in that case. If you stop using our services in between the term,
              we will not refund you the fees paid by you for the remaining
              term. We reserve the right to modify or terminate the SmartQC
              service(s) for any reason, without notice at any time.
            </p>
            <p>
              Fraud: Without limiting any other remedies, SmartQC may suspend
              or terminate your account if we suspect that you (by conviction,
              settlement, insurance or investigation, or otherwise, forcefully
              removed our branding) have engaged in fraudulent activity in
              connection with the services offered by us.
            </p>
            <p>
              Note: No refunds are offered, even if a plan is canceled mid-month
            </p>

            <p className="fw-bold fs-4 mt-5">
              Monthly Billing, Cancellation, and Refund Policies
            </p>
            <p>
              SmartQC charges and collects in advance for use of the SaaS
              service. All services rendered are non-refundable. Once a customer
              selects a subscription plan and provides billing information,
              SmartQC will calculate a prorated amount with the first payment
              due on signing.
            </p>
            <p>
              All subscriptions monthly, quarterly and yearly renew
              automatically on their due renewal date according to date of
              purchase until officially canceled in writing. Customers may
              cancel anytime by emailing a notice to: compliance@smartqc.io
            </p>
            <p>
              Customers will receive an email from SmartQC confirming that
              their subscription to the SaaS service has been canceled..
            </p>
            <p>
              Important: No refunds or credits for partial months, quarters or
              years of service will be refunded to a customer upon cancellation.
            </p>
            <p>
              All fees are exclusive of all taxes, levies, or duties imposed by
              taxing authorities, and the customer is responsible for payments
              of all such taxes, levies, or duties.
            </p>
            <p>
              The fees that SmartQC charges for the monthly, quarterly, or
              yearly services exclude phone, and Internet access charges, as
              well as other data transmission charges. Any currency exchange
              settlements are based on the customer’s agreement with the payment
              method provider. To be clear: these charges are the customer’s
              responsibility.
            </p>
            <p>
              Customers have the ability to upgrade or downgrade their
              subscription plan for the SaaS services at any time. For any kind
              of upgrade or downgrade in subscription, customer has to write to
              compliance@smartqc.io or make the changes on the SmartQC dashboard.
            </p>
            <p>
              The chosen method of billing will automatically be charged the new
              subscription rate on the next billing cycle.
            </p>
            <p>
              With regards to downgrades on a subscription, SmartQC does not
              issue refunds or credits for partial months of service.
            </p>
            <p>
              SmartQC reserves the right to refuse/cancel a subscription to
              any of the SaaS services bought from SmartQC.io, If SmartQC
              refuses a new or renewing subscription/membership, registrants
              will be offered a refund on prorata basis of pending days in
              subscription plan bought.
            </p>
            <p>
              These above policies apply to all the SaaS services listed on
              SmartQC unless otherwise noted in the corresponding program
              materials.
            </p>
            {/* <p className="fw-bold fs-4 mt-5">Contact Us</p>
            <p>
              If you have questions about the cancelation and refund policy,
              please contact us on smartqc.io
            </p> */}
         
          </Col>
        </Row>
      </Container>
      {/* <Footer /> */}
    </>
  );
}

export default Cancellation;
