import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const CampaignPieChart = ({ data }) => {
  // Provide fallback data if data is missing
  const { labels = ["No Data"], series = [0] } = data;

  // Prepare data for amCharts Pie Chart
  const chartData = labels.map((label, index) => ({
    category: label,
    value: series[index] || 0
  }));

  // Color palette from your original code (match with legend items)
  const colorMap = {
    "Completed Campaign": "#31c579",  // Green
    "Pending Campaign": "#fbba54",  // Yellow
    "New Campaign": "#4293ca",  // Blue
    // Add more categories if necessary
  };

  // Function to clean the legend labels by removing unwanted text (like 'campaign' and 'percent')
  const cleanLabel = (label) => {
    return label.replace(/(campaign|percent)/gi, "").trim();
  };

  useEffect(() => {
    // Create root element
    let root = am5.Root.new("piechartdiv");

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        innerRadius: am5.percent(50)  // Creating a doughnut chart
      })
    );

    // Create series
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        alignLabels: false
      })
    );

    // Set data
    series.data.setAll(chartData);

    // Apply custom colors based on the cleaned category name
    series.slices.template.adapters.add("fill", (fill, target) => {
      const category = target.dataItem.dataContext.category;
      const cleanedCategory = cleanLabel(category); 
      // Clean the label
      return am5.color(colorMap[cleanedCategory] || "#ccc");  // Default color if not found
    });

    series.slices.template.adapters.add("stroke", (stroke, target) => {
      const category = target.dataItem.dataContext.category;
      const cleanedCategory = cleanLabel(category); // Clean the label
      return am5.color(colorMap[cleanedCategory] || "#ccc");  // Default color if not found
    });

    // Create legend
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        marginTop: 15,
        marginBottom: 15,
      })
    );

    // Clean legend labels and apply colors based on the cleaned labels
    legend.data.setAll(series.dataItems);
    legend.labels.template.adapters.add("text", (text, target) => {
      const cleanedCategory = cleanLabel(target.dataItem.dataContext.category);
      return cleanedCategory;
    });

    // Animate the chart
    series.appear(1000, 100);

    // Cleanup function to dispose of the chart when the component unmounts
    return () => {
      root.dispose();
    };
  }, [chartData]);

  return (
    <div
    className="mt-3"
      id="piechartdiv"
      style={{ width: "100%", height: "500px", margin: "0 auto" }}
    ></div>
  );
};

export default CampaignPieChart;
