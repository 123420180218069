import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
function formatNumber(value) {
  if (value < 1000) {
    return value.toString(); 
  } else if (value >= 1000 && value < 1000000) {
    return (value / 1000).toFixed(1).replace(/\.0$/, '') + 'K'; 
  } else if (value >= 1000000 && value < 1000000000) {
    return (value / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'; 
  } else {
    return (value / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B'; 
  }
}
const Credits = ({ dashboardData }) => {
  const navigate=useNavigate();
  return (
    <>
      <div className="text-start">
        <h4>Credits</h4>
        <div className="d-flex justify-content-center">
          <div
            className="my-4 p-4 border sq-db-border-orange rounded-circle text-center d-flex flex-column justify-content-center align-items-center"
            style={{ width: "125px", height: "125px" }}
          >
            <h4 className="display-6 fw-bold">{formatNumber(dashboardData.credits)}</h4>
            <span>Available</span>
          </div>
        </div>
        <div className="d-flex justify-content-end my-3">
          <Button className="border-0 btn-orange p-2"
           onClick={() => navigate("/account/addcredits")}>
            Refill Now
          </Button>
        </div>
      </div>
    </>
  );
};

export default Credits;
