import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { MdCopyright } from "react-icons/md";
import flogo from "../../../assets/img/svg/flogo.svg";
import { Link } from "react-router-dom";
import logo from '../../../assets/img/svg/nlogo.svg'


const Footer = () => {
  return (
    <footer className="footer mt-auto py-3 bg-light">
      <Container fluid className="px-5">
        <Row className="align-items-center justify-content-between">
          {/* Footer Logo */}
          <Col
            xs={12}
            md={4}
            className="text-center text-md-start mb-3 mb-md-0"
          >
            <img src={logo} alt="Footer Logo" className="smartqc-footernav-logo img-fluid" />
          </Col>

          {/* Navigation Links */}
          {/* <Col xs={12} md={4} className="text-center">
            <ul className="list-unstyled d-flex flex-wrap justify-content-center gap-3 mb-0">
              <li>
                <Link href="#" className="text-decoration-none text-dark fs-6">
                  Terms of Services
                </Link>
              </li>
              <li>
                <Link href="#" className="text-decoration-none text-dark fs-6">
                  Support
                </Link>
              </li>
              <li>
                <Link href="#" className="text-decoration-none text-dark fs-6">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link href="#" className="text-decoration-none text-dark fs-6">
                  Cancellation
                </Link>
              </li>
              <li>
                <Link href="#" className="text-decoration-none text-dark fs-6">
                  FAQ
                </Link>
              </li>
            </ul>
          </Col> */}

          {/* Footer Text */}
          <Col xs={12} md={6} className="text-center text-md-start">
            <ul className="list-unstyled d-flex flex-wrap justify-content-center gap-3 mb-0">
              <li>
                <Link to={"/terms-of-service"} className="text-decoration-none text-dark fs-6">
                  Terms of Services
                </Link>
              </li>
              <li>
                <Link to={"/support"} className="text-decoration-none text-dark fs-6">
                  Support
                </Link>
              </li>
              <li>
                <Link to={"/privacy-policy"} className="text-decoration-none text-dark fs-6">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to={"/cancellation"} className="text-decoration-none text-dark fs-6">
                  Cancellation
                </Link>
              </li>
              <li>
                <Link to={"/faq"} className="text-decoration-none text-dark fs-6">
                  FAQ
                </Link>
              </li>
            </ul>
            <p className="text-muted mb-0 f-text">
              &copy; smartqc. Missing the cookie banner? Go ahead and browse, we
              don’t track you.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
