import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Container, Row, Col } from "react-bootstrap";
import Loading from "../../../assets/img/dashbord/loading.gif";

const Allinvoice = ({ invoices }) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(invoices);

  useEffect(() => {
    // Filter invoices based on search text
    if (searchText === "") {
      setFilteredData(invoices);
    } else {
      const filtered = invoices.filter((invoice) => {
        const searchTerm = searchText.toLowerCase();
        const amountString =
          `${invoice.amount} ${invoice.currency}`.toLowerCase();
        const dateString = new Date(invoice.createdAt)
          .toLocaleDateString()
          .toLowerCase();

        return (
          invoice.customerName.toLowerCase().includes(searchTerm) ||
          invoice.status.toLowerCase().includes(searchTerm) ||
          dateString.includes(searchTerm) ||
          amountString.includes(searchTerm)
        );
      });
      setFilteredData(filtered);
    }
  }, [searchText, invoices]);

  // Define table columns
  const columns = [
    {
      name: "Sr. No",
      selector: (row, index) => (index + 1).toString().padStart(2, "0"),
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (row) => row.customerName,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => `${row.amount} ${row.currency.toUpperCase()}`,
      sortable: true,
    },
    {
      name: "Payment type",
      selector: (row) => row.type || "Subscription", // Fallback to "Subscription"
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) => {
        const date = new Date(row.createdAt);
        const formattedDate = date.toLocaleDateString("en-GB"); 
        return formattedDate;
      },
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <span
          className={`badge ${
            row.status.toLowerCase() === "succeeded"
              ? "status-complete"
              : row.status.toLowerCase() === "pending"
              ? "status-pending"
              : row.status.toLowerCase() === "failed"
              ? "status-failed"
              : "status-default" // Default status if none of the above match
          }`}
        >
          {
            row.status === "succeeded"
              ? "Completed"
              : row.status === "pending"
              ? "Pending"
              : row.status === "failed"
              ? "Failed"
              : "Unknown" // Default text if none of the above match
          }
        </span>
      ),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <button
          className="btn btn-orange btn-sm"
          onClick={() => handleDownload(row.receiptUrl)}
        >
          Download
        </button>
      ),
    },
  ];

  const handleDownload = (url) => {
    if (url) {
      // Check if the URL is valid (basic HTTP/HTTPS validation)
      const isValidUrl = /^(http|https):\/\/[^ "]+$/.test(url);

      if (isValidUrl) {
        // Open the URL in the same window or a new tab
        window.open(url, "_blank"); // Open in a new tab (default behavior)
      } else {
        alert("Invalid receipt URL.");
      }
    } else {
      alert("Receipt URL is not available.");
    }
  };

  const isEmpty = filteredData.length === 0;

  return (
    <Container fluid className="px-0">
      {invoices.length === 0 ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <img src={Loading} alt="Loading" width={25} />
        </div>
      ) : isEmpty ? (
        <Row>
          <Col className="p-4">
            <Row>
              <Col lg={6}>
                <div className="p-4 rounded-3 bg-white">
                  <h5 className="fw-bold">No data found</h5>
                  <p>There is no available data to display at the moment.</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row className="mb-1">
          <Col lg={12}>
            {/* Search Bar */}
            <div className="search-button-container px-0 py-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search Invoices"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                style={{ width: "25%" }}
              />
            </div>

            {/* DataTable */}
            <DataTable
              className="invoice-data-table"
              columns={columns}
              data={filteredData}
              pagination
              highlightOnHover
            />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Allinvoice;
