import React, { useEffect, useState } from "react";
import LandingPage from "./components/LandingPage";

const App = () => {
  const [isPageLoaded, setIsPageLoaded] = useState(false); // Track when page is fully loaded

  useEffect(() => {
    const handleWindowLoad = () => {
      setIsPageLoaded(true); // Update state when the window (DOM + CSS + images) is fully loaded
    };

    // Check if the page is already loaded
    if (document.readyState === "complete") {
      setIsPageLoaded(true);
    } else {
      // Listen for the window load event
      window.addEventListener("load", handleWindowLoad);
    }

    // Cleanup the event listener
    return () => {
      window.removeEventListener("load", handleWindowLoad);
    };
  }, []);

  return (
    <>
      {/* <Navigation /> */}
      <LandingPage />
    </>
  );
};

export default App;
