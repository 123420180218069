import React from "react";
import { Button } from "react-bootstrap";
import { HiOutlineEye } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

const RecentCampaigns = ({ latestCampaigns }) => {
  const navigate=useNavigate();
  return (
    <div>
      <h4>Recent Campaigns</h4>
      {latestCampaigns.length > 0 ? (
        latestCampaigns.map((campaign) => (
          <div key={campaign._id}>
            <div className="d-flex justify-content-between align-items-center border-bottom py-3">
              <div>
                <h6>{campaign.campaignName}</h6>
                <small>
                  Created at -{" "}
                  {new Date(campaign.createdAt).toLocaleDateString()}
                </small>
              </div>
              <div className="d-flex align-items-center">
                <span
                  className={`badge py-2 ms-2 ${
                    campaign.status === "New"
                      ? "status-new"
                      : campaign.status === "Pending"
                      ? "status-pending"
                      : campaign.status === "Complete"
                      ? "status-complete"
                      : ""
                  }`}
                >
                  {campaign.status}
                </span>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>No recent campaigns available.</p>
      )}
      {/* Place the "View All" button here outside the map */}
      <div className="d-flex justify-content-end mt-4">
      <Button 
      className="border-0 btn-orange p-2" 
      onClick={() => navigate('/account/campaigns')}
    >
      <HiOutlineEye />
      View All
    </Button>
      </div>
    </div>
  );
};

export default RecentCampaigns;
