import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const StripeFailed = () => {
  const [counter, setCounter] = useState(5); // Initialize counter to 5 seconds
  const navigate = useNavigate();

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer); // Cleanup the timer
    } else {
      navigate("/account/billing/plans"); // Redirect to the plans page
    }
  }, [counter, navigate]);

  return (
    <div>
      <p>Redirecting to plans in {counter} seconds...</p>
    </div>
  );
};

export default StripeFailed;
