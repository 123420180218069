import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CiSearch } from "react-icons/ci";
import { HiOutlineMailOpen } from "react-icons/hi";
import { IoPersonOutline } from "react-icons/io5";
import { LiaHeadsetSolid } from "react-icons/lia";

function SectionFaq() {
  return (
    <>
      <div className="services p-3 vh-100 d-flex">
        <Container className="d-flex flex-column justify-content-center">
          <h2 className="fw-bold text-center">
            Frequently Asked <span className="text-orange">Questions</span>
          </h2>

          <p className="text-center text-muted fs-5 py-4">
            SmartQC comes with a step-by-step quality process to help you engage
            your contacts.
          </p>

          <Row className="d-flex justify-content-center">
            <Col xs={12} sm={6} md={6} lg={6} className="p-3">
              <div className="card-equal-height p-3 rounded-4 d-flex flex-column h-100 card-body col-body">
                <IoPersonOutline className="icon" size={30} />
                <div className="pt-2">
                  <h5 className="fw-bold">How to get started?</h5>
                  <p className="text-muted">
                  Learn the basic steps to get started with our platform.
                  </p>
                </div>
              </div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={6} className="p-3">
              <div className="card-equal-height p-3 rounded-4 d-flex flex-column h-100 card-body col-body">
                <CiSearch className="icon" size={30} />
                <div className="pt-2">
                  <h5 className="fw-bold">What is included?</h5>
                  <p className="text-muted">
                  Find out what features are available to you with our service.
                  </p>
                </div>
              </div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={6} className="p-3">
              <div className="card-equal-height p-3 rounded-4 d-flex flex-column h-100 card-body col-body">
                <HiOutlineMailOpen className="icon" size={30} />
                <div className="pt-2">
                  <h5 className="fw-bold">Customer Support</h5>
                  <p className="text-muted">
                  Contact us for support and assistance with your account.
                  </p>
                </div>
              </div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={6} className="p-3">
              <div className="card-equal-height p-3 rounded-4 d-flex flex-column h-100 card-body col-body">
                <LiaHeadsetSolid className="icon" size={30} />
                <div className="pt-2">
                  <h5 className="fw-bold">Privacy Policy</h5>
                  <p className="text-muted">
                  Learn more about how we protect your data and privacy.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionFaq;
