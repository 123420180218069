import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import {
  FaUser,
  FaEnvelope,
  FaMapMarkerAlt,
  FaCity,
  FaHashtag,
} from "react-icons/fa";
import axios from "axios";
import { getCookie } from "../../utils/auth";
import { toast } from "react-toastify";
import { baseURL } from "../../utils/constant";
import Flag from "react-world-flags"; 
import Select from "react-select"; 

const Myaccount = () => {
  const [user, setUser] = useState({
    credits: 0,
    firstName: "",
    lastName: "",
    planExpiresOn: "",
    planName: "",
    email: "",
  });

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [pincode, setPincode] = useState("");

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  const headers = {
    Authorization: `Bearer ${getCookie("bearerToken")}`,
  };

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${baseURL}dashboard/getMergedData`, {
        headers,
      });

      const userData = response.data.data.find(
        (item) => item.source === "user"
      );
      const addressData = response.data.data.find(
        (item) => item.source === "user" && item.address
      )?.address;

      if (userData) {
        setUser({
          credits: userData.credits,
          firstName: userData.firstName,
          lastName: userData.lastName,
          planExpiresOn: userData.planExpiresOn,
          planName: userData.planName,
          email: userData.email,
        });

        setFirstName(userData.firstName);
        setLastName(userData.lastName);
        setEmail(userData.email);
      }

      if (addressData) {
        setAddress1(addressData.address_line_one || "");
        setAddress2(addressData.address_line_two || "");
        setCity(addressData.city || "");
        setState(addressData.state || "");
        setCountry({
          value: addressData.country,
          label: addressData.country,
        });
        setPincode(addressData.postal || "");
      }
    } catch (err) {
      toast.error("Failed to fetch user data");
    }
  };
  const handlePersonalInfoSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      firstName: firstName,
      lastName: lastName,
      email: email, 
    };

    try {
      const response = await axios.post(
        `${baseURL}user/editUserByUserId`, 
        payload,
        { headers }
      );

      if (response.status === 200) {
        toast.success("Personal information updated successfully!");
        fetchUserData(); 
      }
    } catch (err) {
      toast.error("Failed to update personal information. Please try again.");
    }
  };

  const fetchCountriesAndStates = async () => {
    try {
      const response = await axios.get(
        "https://countriesnow.space/api/v0.1/countries/states"
      );
      setCountries(
        response.data.data.map((country) => ({
          label: country.name,
          value: country.name,
          iso2: country.iso2, 
          states: country.states,
        }))
      );
    } catch (err) {
      toast.error("Failed to fetch countries and states");
    }
  };

  const fetchStates = (selectedCountry) => {
    const countryData = countries.find((c) => c.value === selectedCountry);
    if (countryData) {
      setStates(
        countryData.states.map((state) => ({
          label: state.name,
          value: state.state_code, 
        }))
      );
    }
  };

  const handleCountryChange = (selectedCountry) => {
    setCountry(selectedCountry);
    fetchStates(selectedCountry.value); 
  };

  const handleStateChange = (selectedState) => {
    setState(selectedState.value); 
  };

  const handleAddressSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      address_line_one: address1,
      address_line_two: address2,
      city: city,
      state: state,
      country: country.value, // Use country value
      postal: pincode,
    };

    try {
      const response = await axios.post(
        `${baseURL}payment/saveBillingAddress`,
        payload,
        { headers }
      );
      if (response.status === 200) {
        toast.success("Address saved successfully!");
      }
    } catch (error) {
      toast.error("Failed to save address. Please try again.");
    }
  };

  useEffect(() => {
    fetchUserData();
    fetchCountriesAndStates();
  }, []);

  return (
    <Container fluid className="p-4 bg-light">
      <Row className="g-4 d-flex flex-column">
        <h3 className="fw-bold">My Account</h3>
        <hr />
        {/* Personal Information Section */}
        <Col md={12}>
          <div className="p-4 bg-white shadow rounded-4 h-100">
            <h5 className="mb-4 text-dark">Personal Information</h5>
            <Form onSubmit={handlePersonalInfoSubmit}>
              <Row>
                {/* First Name */}
                <Col md={3}>
                  <Form.Group className="mb-4">
                    <Form.Label className="fw-semibold text-secondary">
                      First Name
                    </Form.Label>
                    <InputGroup className="shadow-sm ">
                      <InputGroup.Text className="bg-white border-0 text-secondary">
                        <FaUser />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Enter First Name"
                        className="rounded-end-pill border-0 shadow-sm "
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>

                {/* Last Name */}
                <Col md={3}>
                  <Form.Group className="mb-4">
                    <Form.Label className="fw-semibold text-secondary">
                      Last Name
                    </Form.Label>
                    <InputGroup className="shadow-sm ">
                      <InputGroup.Text className="bg-white border-0 text-secondary">
                        <FaUser />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Enter Last Name"
                        className="rounded-end-pill border-0 shadow-sm"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              {/* Email */}
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-4">
                    <Form.Label className="fw-semibold text-secondary">
                      Email Address
                    </Form.Label>
                    <InputGroup className="shadow-sm">
                      <InputGroup.Text className="bg-white border-0 text-secondary">
                        <FaEnvelope />
                      </InputGroup.Text>
                      <Form.Control
                        type="email"
                        placeholder="Enter Email Address"
                        className="rounded-end-pill border-0 shadow-sm"
                        disabled
                        value={email}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              <div className="row">
                <Col lg={6} className="d-flex justify-content-end">
                  <Button
                    variant="primary"
                    type="submit"
                    className="rounded-pill px-4 py-2 shadow-sm btn-orange border-0"
                  >
                    Update Information
                  </Button>
                </Col>
              </div>
            </Form>
          </div>
        </Col>

        {/* Address Information Section */}
        <Col md={12}>
          <div className="p-4 bg-white shadow rounded-4 h-100">
            <h5 className="mb-4 text-dark">Address Information</h5>
            <Form onSubmit={handleAddressSubmit}>
              {/* Address Line 1 */}
              <Row className="d-flex flex-row">
                <Col md={3} lg={3}>
                  <Form.Group className="mb-4">
                    <Form.Label className="fw-semibold text-secondary">
                      Address Line 1
                    </Form.Label>
                    <InputGroup className="shadow-sm">
                      <InputGroup.Text className="bg-white border-0 text-secondary">
                        <FaMapMarkerAlt />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Enter Address Line 1"
                        className="rounded-end-pill border-0 shadow-sm"
                        value={address1}
                        onChange={(e) => setAddress1(e.target.value)} 
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col md={3} lg={3}>
                  <Form.Group className="mb-4">
                    <Form.Label className="fw-semibold text-secondary">
                      Address Line 2
                    </Form.Label>
                    <InputGroup className="shadow-sm">
                      <InputGroup.Text className="bg-white border-0 text-secondary">
                        <FaMapMarkerAlt />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Enter Address Line 2"
                        className="rounded-end-pill border-0 shadow-sm"
                        value={address2}
                        onChange={(e) => setAddress2(e.target.value)} 
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              {/* Address Line 2 */}

              {/* Country */}
              <Row>
                <Col md={3} lg={3}>
                  <Form.Group className="mb-4">
                    <Form.Label className="fw-semibold text-secondary">
                      Country
                    </Form.Label>
                    <Select
                      options={countries.map((c) => ({
                        value: c.value,
                        label: (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Flag
                              code={c.iso2.toLowerCase()}
                              style={{ width: 20, marginRight: 10 }}
                            />
                            {c.label}
                          </div>
                        ),
                      }))}
                      value={countries.find((c) => c.value === country)}
                      onChange={handleCountryChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={3}>
                  <Form.Group className="mb-4">
                    <Form.Label className="fw-semibold text-secondary">
                      State
                    </Form.Label>
                    <Select
                      options={states}
                      value={states.find((s) => s.value === state)}
                      onChange={handleStateChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* State */}

              <Row>
                {/* City Field */}
                <Col md={3}>
                  <Form.Group className="mb-4">
                    <Form.Label className="fw-semibold text-secondary">
                      City
                    </Form.Label>
                    <InputGroup className="shadow-sm">
                      <InputGroup.Text className="bg-white border-0 text-secondary">
                        <FaCity />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Enter City"
                        className="rounded-end-pill border-0 shadow-sm"
                        value={city}
                        onChange={(e) => setCity(e.target.value)} 
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>

                {/* Pincode Field */}
                <Col md={3}>
                  <Form.Group className="mb-4">
                    <Form.Label className="fw-semibold text-secondary">
                      Pincode
                    </Form.Label>
                    <InputGroup className="shadow-sm">
                      <InputGroup.Text className="bg-white border-0 text-secondary">
                        <FaHashtag />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Enter Pincode"
                        className="rounded-end-pill border-0 shadow-sm"
                        value={pincode}
                        onChange={(e) => {
                          // Allow only numbers and limit to 5 digits
                          const value = e.target.value;
                          if (/^\d{0,5}$/.test(value)) {
                            setPincode(value);
                          }
                        }}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <div className="row">
                <Col lg={6} className="d-flex justify-content-end">
                  <Button
                    variant="success"
                    type="submit"
                    className="rounded-pill px-4 py-2 shadow-sm btn-orange border-0"
                  >
                    Save Address
                  </Button>
                </Col>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Myaccount;
