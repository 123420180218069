import React from "react";
import { Button } from "react-bootstrap";
import { HiOutlineEye } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

const Leads = ({ dashboardData }) => {
  const navigate=useNavigate();
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4>Leads</h4>
        <span className="fs-1 fw-bold">{dashboardData.leadCount}</span>
      </div>
      <div className="d-flex flex-column mt-3">
        <div className="d-flex justify-content-between align-items-center py-2">
          <h4 className="text-success">
            72 <span className="fs-6 text-dark">Verified</span>
          </h4>
          <span className="text-primary">View</span>
        </div>
        <hr />
        <div className="d-flex justify-content-between align-items-center py-2">
          <h4 className="text-danger">
            30 <span className="fs-6 text-dark">Failed</span>
          </h4>
          <span className="text-primary">View</span>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-4">
        <Button className="border-0 btn-orange p-2"  onClick={() => navigate('/account/campaigns')}>
          <HiOutlineEye />
          View All
        </Button>
      </div>
    </>
  );
};

export default Leads;
