import React from "react";
import { Row, Col, Container } from "react-bootstrap";

const Section4New = () => {
  return (
    <Container className="">
      <Row className="justify-content-center align-items-center p-4">
        <Col className="hva py-5 text-center position-relative d-flex flex-column align-items-center justify-content-center w-75">
          <h3 className="fw-bold">
          Try SmartQC Risk-Free With 
            <span className="text-orange"> 100 Credits!</span>
          </h3>
          <p className="py-4 text-muted">
          Kickstart your journey with 100 free credits, valid for 15 days. Sign up now and explore the full potential —no cost, no risk!
          </p>
          <button className="btn btn-orange mt-3">Claim 100 Credits</button>
        </Col>
      </Row>
    </Container>
  );
};

export default Section4New;
