import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "../redux/dashboardSlice"; // Import the async thunk
import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Alert,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { clearAuthentication, getCookie } from "../utils/auth";
import { PiUserCircleLight } from "react-icons/pi";
import axios from "axios";
import { baseURL } from "../utils/constant";
function formatNumber(value) {
  if (value < 1000) {
    return value.toString(); // Keep it as is for numbers below 1000
  } else if (value >= 1000 && value < 1000000) {
    return (value / 1000).toFixed(1).replace(/\.0$/, "") + "K"; // Format to 'K'
  } else if (value >= 1000000 && value < 1000000000) {
    return (value / 1000000).toFixed(1).replace(/\.0$/, "") + "M"; // Format to 'M'
  } else {
    return (value / 1000000000).toFixed(1).replace(/\.0$/, "") + "B"; // Format to 'B'
  }
}
const SubNav = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Access Redux state
  const userData = useSelector((state) => state.dashboard.dashboardData.user);
  const campaignCount = useSelector(
    (state) => state.dashboard.dashboardData.campaignCount
  );
  const credits = useSelector((state) => state.dashboard.dashboardData.credits);
  const [showAlert, setShowAlert] = useState(false);
  const [expireAlert, setExpireAlert] = useState(false);
  const formattedCredits = formatNumber(credits);
  const headers = {
    Authorization: `Bearer ${getCookie("bearerToken")}`,
  };

  // Logout Handler
  const handleLogOut = () => {
    Swal.fire({
      title: "Are you sure to sign out?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f76a28",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const bearerToken = getCookie("bearerToken");
          await axios.post(
            `${baseURL}auth/logOut`, // Replace with the correct logout API endpoint
            {},
            {
              headers: {
                Authorization: `Bearer ${bearerToken}`,
              },
            }
          );
          clearAuthentication(); // Clear cookies and localStorage
          toast.success("Logged out successfully");
          navigate("/login"); // Redirect to login page
        } catch (error) {
          toast.error("Failed to log out. Please try again.");
        }
      }
    });
  };

  // Fetch data only if Redux state is empty
  useEffect(() => {
    if (!userData || Object.keys(userData).length === 0) {
      dispatch(fetchDashboardData());
    } else {
      // If user data is already in the state, no need to fetch it again
      if (userData.status !== "Active") setShowAlert(true);
      if (new Date(userData.planExpiresOn) < new Date()) setExpireAlert(true);
    }
  }, [dispatch, userData]);

  return (
    <>
      <Navbar expand="lg" className="bg-white sticky-top sb-shadow-bottom">
        <Container fluid className="py-2">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <NavDropdown
                title={formattedCredits}
                className="credit-header-nav me-3"
              >
                <NavDropdown.Item
                  onClick={() => navigate("/account/addcredits")}
                >
                  Add Credits
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title={
                  <span className="">
                    <PiUserCircleLight className="me-2" size={"30px"} />{" "}
                    {`${userData?.firstName} ${userData?.lastName}` || "User"}
                  </span>
                }
                id="user-dropdown"
                className="user-drpdown"
              >
                <NavDropdown.Item onClick={() => navigate("/account/profile")}>
                  Profile
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/account/settings")}>
                  Settings
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={handleLogOut}>
                  Sign out
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {showAlert && (
        <AlertSection
          message="Your email address is not verified. Please check your inbox or"
          linkText="click here"
          onLinkClick={(e) => {
            e.preventDefault();
          }}
        />
      )}

      {expireAlert && (
        <AlertSection
          message={`Your ${userData.planName} plan has expired.`}
          buttonText="Upgrade Plan"
          onButtonClick={() => navigate("/account/billing/plans")}
        />
      )}
    </>
  );
};

// Reusable Alert Section
const AlertSection = ({
  message,
  linkText,
  onLinkClick,
  buttonText,
  onButtonClick,
}) => (
  <Container fluid className="mt-3">
    <Alert variant="warning">
      <Row>
        <Col>
          <p className="mb-0">
            {message}{" "}
            {linkText && (
              <a href="#" onClick={onLinkClick}>
                {linkText}
              </a>
            )}
          </p>
        </Col>
        {buttonText && (
          <Col className="text-end">
            <Button variant="btn btn-orange" size="sm" onClick={onButtonClick}>
              {buttonText}
            </Button>
          </Col>
        )}
      </Row>
    </Alert>
  </Container>
);

export default SubNav;
