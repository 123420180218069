import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../utils/auth";
import { baseURL } from "../utils/constant";

export const fetchDashboardData = createAsyncThunk(
  "dashboard/fetchDashboardData",
  async (source = "", { getState, rejectWithValue }) => {
    const state = getState();
    const dashboardData = state.dashboard.dashboardData;

    // Check if the source is not "fromlogin" and state is already populated
    if (
      source !== "fromlogin" &&
      dashboardData &&
      Object.keys(dashboardData).length > 0 &&
      dashboardData.campaignCount > 0
    ) {
      // Skip API call and resolve with current data
      return dashboardData;
    }

    const bearerToken = getCookie("bearerToken");
    const headers = {
      Authorization: `Bearer ${bearerToken}`,
    };

    try {
      const response = await axios.get(`${baseURL}dashboard/getMergedData`, {
        headers,
      });
      return response.data?.data || [];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
  const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
      isLoading: true,
      dashboardData: {
        campaignCount: 0,
        leadCount: 0,
        credits: 0,
        user: {
          firstName: "",
          lastName: "",
          planName: "",
          planExpiresOn: "",
        },
        pieChart: { labels: [], series: [] },
      },
      latestCampaigns: [],
      usageData: [],
      error: null,
    },
    reducers: {
      resetDashboard: (state) => {
        state.isLoading = true;
        state.dashboardData = {
          campaignCount: 0,
          leadCount: 0,
          credits: 0,
          user: {
            firstName: "",
            lastName: "",
            planName: "",
            planExpiresOn: "",
          },
          pieChart: { labels: [], series: [] },
        };
        state.latestCampaigns = [];
        state.usageData = [];
        state.error = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchDashboardData.pending, (state) => {
          state.isLoading = true;
          state.error = null;
        })
        .addCase(fetchDashboardData.fulfilled, (state, action) => {
          const data = action.payload;
  
          // Update dashboard state with new data
          const campaignData = data.find((item) => item.source === "campaign");
          const userData = data.find((item) => item.source === "user");
          const pieChartData = data.find((item) => item.source === "pieChart");
          const usageData =
            data.find((item) => item.source === "usage")?.data?.graphData || [];
  
          state.usageData = usageData;
          state.dashboardData = {
            campaignCount: campaignData?.data.length || 0,
            leadCount: campaignData?.data.reduce((sum, c) => sum + c.leads || 0, 0),
            credits: userData?.credits || 0,
            user: {
              firstName: userData?.firstName || "",
              lastName: userData?.lastName || "",
              planName: userData?.planName || "",
              planExpiresOn: userData?.planExpiresOn || "",
            },
            pieChart: {
              labels: pieChartData?.labels || [],
              series: pieChartData?.series || [],
            },
          };
  
          state.latestCampaigns = campaignData?.data.slice(0, 2) || [];
          state.isLoading = false;
        })
        .addCase(fetchDashboardData.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.payload;
        });
    },
  });
  
  export const { resetDashboard } = dashboardSlice.actions;
  export default dashboardSlice.reducer;

