import { Row, Col, Form, Button, Container } from "react-bootstrap";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import useParams here
import { useParams } from "react-router-dom";
// import logo from "../../assets/img/home/logo.png";
import axios from "axios";
import { baseURL } from "../../utils/constant";
import { toast } from "react-toastify";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [bearerToken, setBearerToken] = useState("");
  const navigate = useNavigate();
  const { token } = useParams();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password !== password1) {
      toast("Passwords do not match!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        // transition: Bounce,
      });
    } else {
      toast("Passwords matched. Submitting form...", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        // transition: Bounce,
      });

      const payload = {
        password,
        password1,
      };
      try {
        axios
          .post(`${baseURL}auth/changePassword`, payload, {
            headers,
          })
          .then((res) => {
            if (res.data.status === 200) {
              toast("Password Change Successfully!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                // transition: Bounce,
              });
              setBearerToken(res.data.newToken);
              navigate("/login");
            } else {
              toast("Failed to generate new token!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                // transition: Bounce,
              });
            }
          })
          .catch((err) => {
            toast("Password Change Failed!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              // transition: Bounce,
            });
          });
      } catch (error) {
        toast("An error occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          // transition: Bounce,
        });
      }
    }
  };

  return (
    <>
      <div className="vh-100 d-flex align-items-center justify-content-center bg-login bg-white">
        <Container>
          <Row className="text-center mb-4">
            <Col lg={12}>
              <a
                className="text-decoration-none text-dark fw-lighter fs-2"
                href="#"
              >
                smart<span className="text-orange fw-bold">qc</span>
              </a>
            </Col>
          </Row>
          <Row>
            <Col lg={5} className="mx-auto">
              <Col lg={12} className="p-5 rounded-4 bg-white border">
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="password"
                      placeholder="New Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="border-1 border-bottom rounded-3"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="password"
                      value={password1}
                      onChange={(e) => setPassword1(e.target.value)}
                      placeholder="Confirm New Password"
                      className="border-1 border-bottom rounded-3"
                    />
                  </Form.Group>

                  <div className="d-grid gap-2">
                    <Button
                      variant="btn btn-orange"
                      type="submit"
                      className="rounded-3"
                    >
                      Continue
                    </Button>
                  </div>
                </Form>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ResetPassword;
