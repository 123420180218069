import React, { useEffect, useState } from "react";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const UsageCharts = ({ data }) => {
  const [chart, setChart] = useState(null);

  useEffect(() => {
    if (!data || data.length === 0) return; // Prevent rendering if data is empty

    // Create root element
    let root = am5.Root.new("usagechart");

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    let chartInstance = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
        paddingLeft: 0,
      })
    );

    // Add cursor
    let cursor = chartInstance.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
      })
    );
    cursor.lineY.set("visible", false);

    // Create axes
    let xAxis = chartInstance.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.5,
        baseInterval: {
          timeUnit: "day",
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 80,
          minorGridEnabled: true,
          pan: "zoom",
        }),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    let yAxis = chartInstance.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 1,
        renderer: am5xy.AxisRendererY.new(root, {
          pan: "zoom",
        }),
      })
    );

    // Add series
    let series = chartInstance.series.push(
      am5xy.SmoothedXLineSeries.new(root, {
        name: "Usage Data",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueY} Campaigns Count",
        }),
      })
    );
    series.strokes.template.setAll({
      stroke: am5.color(0x52A1FE), // Set to blue
      strokeWidth: 5,
    });

    // Gradient fill for the area under the series
    let gradient = am5.Gradient.new(root, {
      stops: [
        { color: am5.color(0x2D60FF), offset: 0, opacity: 0.25 },
        { color: am5.color(0x2D60FF), offset: 1, opacity: 0 },
      ],
      rotation: 90,
    });

    series.fills.template.setAll({
      visible: true,
      fill: gradient,
      fillOpacity: 0.3,
    });

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationY: 0,
        sprite: am5.Circle.new(root, {
          radius: 4,
          stroke: root.interfaceColors.get("background"),
          strokeWidth: 2,
          fill: series.get("fill"),
        }),
      });
    });

    // Add scrollbar
    chartInstance.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
      })
    );

    // Update chart data dynamically
    const formattedData = data.map(item => ({
      date: new Date(item.category).getTime(),  // Ensure 'category' is in proper Date format
      value: item.data,
    }));

    series.data.setAll(formattedData);

    // Make chart and series appear with animation
    series.appear(1000);
    chartInstance.appear(1000, 100);

    setChart(chartInstance);

    // Cleanup function to dispose of the chart when the component unmounts
    return () => {
      root.dispose();
    };
  }, [data]); // Re-run effect when `data` changes

  if (!data || data.length === 0) {
    return <p>No data available for the chart.</p>;
  }

  return <div id="usagechart" style={{ width: "100%", height: "500px" }}></div>;
};

export default UsageCharts;
