import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  MdKeyboardDoubleArrowRight,
  MdOutlineKeyboardDoubleArrowRight,
} from "react-icons/md";
import image2 from "../../../assets/img/svg/pipeline.svg";

function Section2() {
  return (
    <>
      <div className="py-5 bg-body  ">
        <Container>
        <Row className="mb-4">
  <Col
    lg={7} 
    sm={12} 
    className="text-center text-lg-start d-flex flex-column align-items-center align-items-lg-start"
  >
    <h3 className="fw-bold text-center text-lg-start">
     
    Why Choose {" "}
      <span className="text-orange">SmartQC?</span>
    </h3>
  </Col>
</Row>
          <Row className="align-items-center">
            
            <Col lg={6} sm={12} className="mb-4 mb-lg-0">
             
              <div>
                <div className="my-3 ">
                  <p className="text-orange fs-5 mb-2 fw-bold">
                    <span className="">
                      <MdOutlineKeyboardDoubleArrowRight size={"24px"} />
                    </span>
                    <span className="mx-2">
                      <span>Human-Verified Accuracy</span>
                    </span>
                  </p>
                  <p className="text-muted px-4 mx-2 ">
                  No AI shortcuts—just precise, human-validated results.
                  </p>
                </div>
                <div className="my-5 ">
                  <p className="text-orange fs-5 mb-2 fw-bold">
                    <span className="">
                      <MdOutlineKeyboardDoubleArrowRight size={"24px"} />
                    </span>
                    <span className="mx-2">
                      <span>Effortless Dashboard</span>
                    </span>
                  </p>
                  <p className="text-muted px-4 mx-2 ">
                  Upload your list. Verify your leads. Track everything seamlessly.
                  </p>
                </div>
                <div className="my-4 ">
                  <p className="text-orange fs-5 mb-2 fw-bold">
                    <span className="">
                      <MdOutlineKeyboardDoubleArrowRight size={"24px"} />
                    </span>
                    <span className="mx-2">
                      <span>Compliance You Can Trust</span>
                    </span>
                  </p>
                  <p className="text-muted px-4 mx-2 py-2">
                  Your data is secure, adhering to global standards.
                  </p>
                </div>
              </div>
            </Col>

            <Col lg={6} className=" ">
              <img src={image2} alt="" className="img-fluid " />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Section2;
