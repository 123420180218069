import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getCookie } from "../../../utils/auth";
import { baseURL } from "../../../utils/constant";
import Loading from "../../../assets/img/dashbord/loading.gif";
import Allinvoice from "./Allinvoice";
import { fetchDashboardData } from "../../../redux/dashboardSlice"; // Adjust path as needed
import { useDispatch, useSelector } from "react-redux";

const formatValue = (value) => {
  if (value < 1000) {
    return value.toString(); // Keep it as is for numbers below 1000
  } else if (value >= 1000 && value < 1000000) {
    return (value / 1000).toFixed(1).replace(/\.0$/, "") + "K"; // Format to 'K'
  } else if (value >= 1000000 && value < 1000000000) {
    return (value / 1000000).toFixed(1).replace(/\.0$/, "") + "M"; // Format to 'M'
  } else {
    return (value / 1000000000).toFixed(1).replace(/\.0$/, "") + "B"; // Format to 'B'
  }
};
const Billing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [invoices, setInvoices] = useState([]);
  const [error, setError] = useState(null);
  const bearerToken = getCookie("bearerToken");
  const { dashboardData, isLoading } = useSelector((state) => state.dashboard);

  useEffect(() => {
    // Dispatch fetch action if needed
    if (!dashboardData?.user?.planName) {
      dispatch(fetchDashboardData());
    }
  }, [dispatch, dashboardData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseURL}payment/getLogsDataByUserId`,
          {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
          }
        );

        // Map invoice data from API response
        const invoiceData = response.data.map((item) => ({
          id: item._id,
          amount: item.amount,
          currency: item.currency,
          createdAt: item.createdAt,
          status: item.status,
          transactionId: item.transactionId,
          customerName: item.customerName,
          receiptUrl: item.receiptUrl,
          type: item.type,
        }));

        // Mock user data for demonstration
        const userData = {
          planName: "Premium",
          planExpiresOn: "2024-12-31",
          credits: 500,
        };

        setUser(userData);
        setInvoices(invoiceData);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err.response?.status === 401) {
          navigate("/");
        } else {
          setError("Failed to fetch billing information. Please try again.");
        }
      }
    };

    fetchData();
  }, [navigate, bearerToken]);

  const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB"); // Formats as DD/MM/YYYY
  };

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <img src={Loading} alt="Loading" width={25} />
        </div>
      ) : error ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <p className="text-danger">{error}</p>
        </div>
      ) : (
        <Container fluid>
          <Row>
            <Col lg={12} className="px-0">
              <Col className="p-5">
                <Row>
                  <h4 className="fw-bold mb-4">Billing</h4>
                  <Col
                    lg={6}
                    md={10}
                    sm={6}
                    className="bg-orange1 rounded-4 my-4 p-4"
                  >
                    <Row className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
                      {/* Left Section */}
                      <Col md={8} sm={12}  className="text-start mb-4 mb-md-0">
                        <div className="my-1 display-8 ActivePlan">
                          Active Plan
                        </div>
                        <h5 className="fw-bold fs-3">
                          {dashboardData.user.planName} Plan
                        </h5>
                        <span>
                          Valid till -{" "}
                          {getFormattedDate(dashboardData.user.planExpiresOn)}
                        </span>

                        <div className="py-3 d-flex flex-wrap gap-2">
                          <Button
                            className="bg-upgradePlan"
                            onClick={() => navigate("/account/addcredits")}
                          >
                            Top-Up Now
                          </Button>

                          <Button
                            className="bg-upgradePlan"
                            onClick={() => navigate("/account/billing/plans")}
                          >
                            Upgrade
                          </Button>
                        </div>
                      </Col>

                      {/* Right Section */}
                      <Col
                        md={4}
                        sm={12}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <div className="credits-circle">
                          <div className="credits-content">
                            <h5 className="credits-number">
                              {formatValue(dashboardData.credits)}
                            </h5>
                            <span className="credits-label">
                              Credits Available
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <div className="my-5">
                    <h5 className="fw-semibold fs-4" id="invoicehistory">
                      Invoice History
                    </h5>
                    <Allinvoice invoices={invoices} />
                  </div>
                </Row>
              </Col>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Billing;
