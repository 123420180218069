import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import image3 from "../../../assets/img/svg/ha.svg";

const Section6New = () => {
  return (
    <div className="section6 vh-100 d-flex">
      <Container className=" d-flex justify-content-center align-items-center p-5">
        <Row className="hva d-flex justify-content-center align-items-center p-5">
          <Col lg={6} md={6} className="p-2 text-center text-lg-start">
            <h3 className="fw-bold">
              Human-Validated{" "}
              <span className="text-orange">
                Accuracy <br /> with SmartQC
              </span>
            </h3>
            <p className="fs-5 text-muted pt-3">
              Unlike fully automated tools, SmartQC combines technology with
              human expertise for precise and reliable data validation. SmartQC
              stands out by combining cutting-edge automation with human
              oversight, ensuring your data is not just processed quickly but
              verified for the highest level of accuracy. This approach reduces
              errors and guarantees top-tier data integrity.
            </p>
          </Col>
          <Col lg={5} md={6} className="text-sm-center">
            <img
              src={image3}
              alt="Human-Validated Accuracy"
              className="img-fluid mx-5"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Section6New;
