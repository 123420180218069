import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { setAuthentication, isLogin } from "../../utils/auth";
import { baseURL } from "../../utils/constant";
import { IoMailOutline } from "react-icons/io5";
import { CiLock } from "react-icons/ci";
import logo from "../../assets/img/login&sign-up/logo.png";
import { resetDashboard, fetchDashboardData } from "../../redux/dashboardSlice";
import { useDispatch } from "react-redux";

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const authenticate = async () => {
      const isLoggedIn = await isLogin();
      if (isLoggedIn) {
        navigate("/account/dashboard");
         // Redirect to dashboard if logged in
      }
    };
    
    authenticate();
  }, [navigate]);


  // Handle form input changes for email and password
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading
    setError(""); // Reset error state
    const payload = { email, password };
    const roleId = "651c2269c5e7c88757479403";
    const config = {
      headers: {
        "role-id": roleId,
      },
    };
  
    try {
      const res = await axios.post(`${baseURL}auth/login`, payload, config);
  
      setAuthentication(res.data.bearerToken);
      localStorage.setItem("token", res.data.bearerToken);
      localStorage.setItem("isSignedIn", "true");
      localStorage.setItem("loginTime", new Date().getTime());
  
      // Reset state and fetch fresh data
      dispatch(resetDashboard());
      await dispatch(fetchDashboardData("fromlogin"));
  
      navigate("/account/dashboard");
  
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (err) {
      setIsLoading(false);
      if (err?.response) {
        setError(err?.response?.data?.message || "An error occurred during login.");
        toast(err?.response?.data?.message || "An error occurred during login.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setError("An unknown error occurred.");
        toast("An unknown error occurred.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  

  // Toggle password visibility
  const handlePasswordToggle = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="d-flex align-items-center bg-login bg-orange-gradient">
      <Container fluid>
        <Row>
          <Col lg={4} className="vh-100 d-flex align-items-center mx-auto">
            <Col lg={10} className="p-0">
              <div className="card border-0 shadow bg-white rounded-5">
                <div className="card-body p-5">
                  <div className="py-3">
                    <Container className="d-flex justify-content-center align-items-center">
                      <Link to="/">
                        <img src={logo} alt="Logo" />
                      </Link>
                    </Container>
                  </div>
                  <h4 className="fw-bold display-6 mb-4 fs-3">Sign in</h4>

                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-4">
                      <Form.Label className="label-email-class px-2">
                        Email
                      </Form.Label>
                      <div className="input-group">
                        <span className="input-group-text bg-white border-end-0">
                          <IoMailOutline className="text-muted" />
                        </span>
                        <Form.Control
                          type="email"
                          className="rounded-end form-control-sm py-3 border-start-0 input-orange"
                          name="email"
                          value={email}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-4 position-relative">
                      <Form.Label className="label-password-class px-2">
                        Password
                      </Form.Label>
                      <div className="input-group password-group">
                        <span className="input-group-text bg-white border-end-0">
                          <CiLock className="text-muted" />
                        </span>
                        <Form.Control
                          type={passwordVisible ? "text" : "password"}
                          className="rounded-end form-control-sm py-3 border-start-0 input-orange w-100"
                          name="password"
                          value={password}
                          onChange={handleInputChange}
                        />
                        <button
                          type="button"
                          className="position-absolute end-0 top-50 translate-middle-y me-2 border-0 bg-transparent password-icon"
                          onClick={handlePasswordToggle}
                        >
                          {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                        </button>
                      </div>
                    </Form.Group>

                    <div className="d-flex justify-content-end mb-3">
                      <Link
                        className="Forgotlink text-decoration-none text-orange fw-medium"
                        to="forgotpassword"
                      >
                        Forgot password?
                      </Link>
                    </div>

                    <div className="d-grid gap-2 mb-4">
                      <Button
                        type="submit"
                        className="btn btn-orange rounded-3 py-3 border-0"
                        disabled={isLoading} // Disable button when loading
                      >
                        {isLoading ? "Logging in..." : "Continue"}
                      </Button>
                    </div>
                  </Form>

                  <p className="text-center">
                    Don't have an account?{" "}
                    <Link
                      to="/register"
                      className="text-decoration-none text-orange"
                    >
                      Sign up
                    </Link>
                  </p>
                  <div>
                    <span class="line">
                      <h2>
                        <span>Or</span>
                      </h2>
                    </span>
                    <div className="text-center">
                      <Link to="/" className="text-decoration-none text-dark">Visit Website</Link>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
