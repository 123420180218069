import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import image from "../../../assets/img/images/section9.png";

function Section9() {
  return (
    <>
      <div className="bg-body ">
        <Container className="d-flex flex-column ">
          {/* Header Section */}
          <Row className="flex-grow-1 d-flex align-items-center justify-content-center text-center py-4">
            <Col xs={12} md={10} lg={9}>
              <h2 className="fw-bold mb-4">
                Discover prospects with verified emails,
                customize outreach at scale, and convert them <br />
                into customers.
              </h2>
              <button className="btn btn-orange mt-3">Get Started</button>
            </Col>
          </Row>

          {/* Image Section */}
          <Row className="flex-grow-1 d-flex align-items-center justify-content-center">
            <Col xs={12} md={10} lg={8}>
              <div className="">
                <img
                  src={image}
                  alt="Promotional"
                  className="img-fluid rounded"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Section9;
