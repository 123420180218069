import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Navbar from "../../components/Navbar.jsx";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer.jsx";


function Termsofservice() {
  return (
    <>
      <Container className="py-5">
        <Row>
          <Col lg={8} className="mx-auto">
            <div className="mb-3">
              <Link to="/" className="text-decoration-none text-black">
                Back to Home Page
              </Link>
            </div>
            <h1 className="mb-5">Terms of Service</h1>
            <p>
              All information in the SmartQC Website ( www.smartqc.io ) is
              provided for information purposes only and does not constitute a
              legal contract between SmartQC and any person or entity unless
              otherwise specified. Information on www.smartqc.io is subject to
              change without prior notice. Although every reasonable effort is
              made to present current and accurate information, SmartQC makes no
              guarantees of any kind.
            </p>
            <p>
              The SmartQC web site may contain information that is created and
              maintained by a variety of sources both internal and external to
              the organization.
            </p>
            <p>
              In no event shall SmartQC be responsible or liable, directly or
              indirectly, for any damage or loss caused or alleged to be caused
              by or in connection with the use of or reliance on any such
              content, goods, or services available on or through the site.
            </p>
            <p>
              Contents of this website are the exclusive property of SmartQC and
              may not be reproduced in any form without the prior written
              consent of SmartQC. All logos, trademarks used of other
              organizations that are associates, customers or partners of
              SmartQC become the legal owners of such content.
            </p>
          </Col>
        </Row>
      </Container>
      {/* <Footer /> */}
    </>
  );
}

export default Termsofservice;
