import React from "react";
import { Container, Row, Col, Form, Button, Dropdown } from "react-bootstrap";
import Navbar from "../../components/Navbar.jsx";
import Footer from "../../components/Footer.jsx";
import { Link } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";

function Support() {
  return (
    <>
      <Container className="py-5">
        <Row>
          <Col lg={8} className="mx-auto">
            <div className="mb-3">
              <Link to="/" className="text-decoration-none text-black">
                Back to Home Page
              </Link>
            </div>
            <h1 className="mb-5">Support</h1>

            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="fw-bold">
                  Which campaign, data or payment is this about?
                </Form.Label>
                <Form.Control type="text" />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="fw-bold">
                  In a few words, what can we help you with?
                </Form.Label>
                <Form.Control type="text" />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label className="fw-bold">
                  Describe the issue in more details.
                </Form.Label>
                <p>
                  Please include all relevant logs, error messages and any other details so we can help you quicker.
                </p>
                <Form.Control as="textarea" rows={3} />
              </Form.Group>
              <div className="text-end">
                <button className=" btn btn-orange ">Send Message</button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
      {/* <Footer /> */}
    </>
  ); 
}

export default Support;
