import React, { useEffect, useRef } from "react";

import Section1 from "../views/pages/Home/Section1";
import Section2 from "../views/pages/Home/Section2";
import Section3 from "../views/pages/Home/Section3";
import Footer from "../views/pages/Home/Footer";
import Section4New from "../views/pages/Home/Section4New";
import Section5New from "../views/pages/Home/Section5New";
import Section6New from "../views/pages/Home/Section6New";
import Section9 from "../views/pages/Home/Section9";
import Section8 from "../views/pages/Home/Section8";
import Section7 from "../views/pages/Home/Section7";
import SectionFaq from "../views/pages/Home/SectionFaq";

import Scrollbar from "smooth-scrollbar";
import Navigation from "../views/pages/Home/Navigation";

function LandingPage() {


  return (
    <>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4New />
      <Section5New />
      <Section6New />
      <Section7 />
      <SectionFaq />
      <Section9 />
      </>
  );
}

export default LandingPage;
