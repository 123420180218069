import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CiSearch } from "react-icons/ci";
import { HiOutlineMailOpen } from "react-icons/hi";
import { IoPersonOutline } from "react-icons/io5";
import { LiaHeadsetSolid } from "react-icons/lia";

function Section7() {
  return (
    <>
      <div className="services p-3 bg-body vh-100 d-flex">
        <Container className="d-flex flex-column justify-content-center">
          <h2 className="fw-bold text-center">
            Almost everything <span className="text-orange">you need</span>
          </h2>

          <p className="text-center text-muted fs-5 py-4">
            SmartQC comes with a step-by-step quality process to help you engage
            your contacts.
          </p>

          <Row className="d-flex justify-content-center">
            <Col xs={12} sm={6} md={3} className="p-3">
              <div className="card-equal-height p-3 rounded-4 d-flex flex-column h-100 card-body col-body">
                <IoPersonOutline className="icon" size={30} />
                <div className="pt-2">
                  <h5 className="fw-bold">Enrichment</h5>
                  <p className="text-muted">
                    Enrichment lets you look up person and email or domain
                    company data based on an.
                  </p>
                </div>
              </div>
            </Col>

            <Col xs={12} sm={6} md={3} className="p-3">
              <div className="card-equal-height p-3 rounded-4 d-flex flex-column h-100 card-body col-body">
                <CiSearch className="icon" size={30} />
                <div className="pt-2">
                  <h5 className="fw-bold">Discovery</h5>
                  <p className="text-muted">
                    Discovery lets you search for companies via specific
                    criteria.
                  </p>
                </div>
              </div>
            </Col>

            <Col xs={12} sm={6} md={3} className="p-3">
              <div className="card-equal-height p-3 rounded-4 d-flex flex-column h-100 card-body col-body">
                <HiOutlineMailOpen className="icon" size={30} />
                <div className="pt-2">
                  <h5 className="fw-bold">Email lookup</h5>
                  <p className="text-muted">
                    Look up a person by their email address.
                  </p>
                </div>
              </div>
            </Col>

            <Col xs={12} sm={6} md={3} className="p-3">
              <div className="card-equal-height p-3 rounded-4 d-flex flex-column h-100 card-body col-body">
                <LiaHeadsetSolid className="icon" size={30} />
                <div className="pt-2">
                  <h5 className="fw-bold">Voice call check</h5>
                  <p className="text-muted">
                    Authenticate identity with a phone call.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Section7;
