import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Papa from "papaparse"; // CSV parsing library
import { ToastContainer, toast } from "react-toastify"; // For notifications
import "react-toastify/dist/ReactToastify.css"; // Toastify styles
import { RxCross1 } from "react-icons/rx";
import csvfileicon from "../../../assets/img/svg/csvfileicon.svg";
import { baseURL } from "../../../utils/constant";
import axios from "axios";
import { getCookie } from "../../../utils/auth";
import Swal from "sweetalert2";

const Uploadcsv = ({ onHide }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [rowCount, setRowCount] = useState(null); // Tracks row count
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();

  const { _id } = useParams(); // Campaign ID
  const bearerToken = getCookie("bearerToken");
  const headers = bearerToken ? { Authorization: `Bearer ${bearerToken}` } : {};

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.type !== "text/csv") {
        alert("Only CSV files are allowed!");
        return;
      }

      setSelectedFile(file);
      setFileName(file.name);

      // Parse the CSV and calculate row count
      Papa.parse(file, {
        complete: (result) => {
          setRowCount(result.data.length - 1); // Assuming the first row is the header
        },
        header: true,
      });
    }
  };

  const handleFileUpload = async () => {
    if (!bearerToken) {
      alert("Authentication failed! Please log in again.");
      return;
    }
  
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("campaignId", _id);
  
      setIsUploading(true);
      try {
        const response = await axios.post(
          `${baseURL}campaign/importAnyFile`,
          formData,
          { headers }
        );
  
        const campaignId = response.data.campaignId || _id;
  
        // Parse CSV data here (you can get result from Papa.parse)
        Papa.parse(selectedFile, {
          complete: (result) => {
            navigate(`/account/showheaders/${campaignId}`, {
              state: { rowCount, csvData: result.data }, // Pass csvData to the next component
            });
          },
          header: true,
        });
      } catch (error) {
        Swal.fire({
          title: "Oops! You're running low on credits",
          text: "To continue uploading your file without interruptions, simply add more credits now. It's quick, easy, and ensures a smooth experience. Don't miss out on completing your task!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#f76a28",
          cancelButtonColor: "#000",
          confirmButtonText: "Add Credits Now",
          customClass: {
            confirmButton: 'btn-orange', // Add class 'btn-orange' to confirm button
          },
          cancelButtonText: "Cancel",
          backdrop: true,
          allowEscapeKey: false,
          allowOutsideClick: false
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              navigate("/account/addcredits"); // Redirect to the credit purchase page
            } catch (error) {
              toast.error("Failed to navigate. Please try again.");
            }
          }
        });
        
         } finally {
        setIsUploading(false);
      }
    } else {
      alert("Please select a file first!");
    }
  };
  
  return (
    <div className="csv d-flex justify-content-center align-items-center bg-light">
      <div className="card shadow-sm p-4" style={{ width: "450px" }}>
        {/* Header Section */}
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h6 className="mb-0 fw-bold">
            <span className="me-2">1/3</span> Upload a CSV
          </h6>
          <button className="border-0 bg-transparent" onClick={onHide}>
            <RxCross1 />
          </button>
        </div>

        {/* Upload Section */}
        <div className="text-center py-3 border rounded bg-light mb-4">
          <img src={csvfileicon} alt="CSV File Icon" className="mb-3" />
          <p className="mb-0">
            <label
              htmlFor="fileUpload"
              className="text-orange text-decoration-underline"
              style={{ cursor: "pointer" }}
            >
              Click to upload
            </label>
            <input
              id="fileUpload"
              type="file"
              accept=".csv"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            &nbsp;or drag and drop.
          </p>
          <small className="text-muted">Only CSV files are accepted.</small>
        </div>

        {/* File Information Section */}
        {fileName && (
          <div className="p-3 border rounded mb-4 bg-white">
            <div className="d-flex align-items-center">
              <div className="text-success me-3">
                <i className="bi bi-check-circle-fill"></i>
              </div>
              <div>
                <p className="mb-1 fw-bold">{fileName}</p>
                <small className="text-muted">
                  {rowCount !== null ? `${rowCount} leads found` : "Loading..."}
                </small>
              </div>
            </div>
          </div>
        )}

        {/* Action Button */}
        <div className="text-end">
          <button
            className="btn btn-orange px-4"
            onClick={handleFileUpload}
            disabled={isUploading}
          >
            {isUploading ? "Uploading..." : "Continue"}
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Uploadcsv;
