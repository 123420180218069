import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import Scrollbar from "smooth-scrollbar";

import AppRoutes from "./routes/AppRoutes";
import "./index.css";
import Navigation from "./views/pages/Home/Navigation";
import { Provider } from "react-redux";
import store from "./redux/store";

const AppWithAuth = () => {
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsAuthenticated(!!token);
    setIsAuthChecked(true);
  }, []);

  if (!isAuthChecked) return null; // Delay rendering until authentication is checked

  return (
    <BrowserRouter>
      <AppRoutes
        isAuthenticated={isAuthenticated}
        setIsAuthenticated={setIsAuthenticated}
      />
    </BrowserRouter>
  );
};

const App = () => {
  useEffect(() => {
    const options = {
      damping: 0.1, // Smoothness of the scrolling
    };

    // Apply smooth scrollbar only to the content area (exclude sidebar)
    const contentElement = document.getElementById("Container");
    if (contentElement) {
      const scrollbarInstance = Scrollbar.init(contentElement, options);

      // Cleanup on unmount
      return () => {
        scrollbarInstance.destroy();
      };
    }
  }, []);

  return <AppWithAuth />;
};

ReactDOM.createRoot(document.getElementById("root")).render(
  <>
    <Provider store={store}>
      <App />
      <ToastContainer />
    </Provider>
  </>
);
